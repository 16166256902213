export const helper = {
  getIdade: (dataNascimento: Date): number => {
    const anoAtual = new Date().getFullYear()
    const idade = anoAtual - dataNascimento.getFullYear()
    const dtAux = new Date(new Date().setFullYear(new Date().getFullYear() - idade))
    if (dataNascimento > dtAux) {
      return idade - 1
    }
    return idade
  },

  getDate: (dateString: string): Date => {
    const [dd, mm, yy] = dateString.split('/')
    return new Date(Number(yy), Number(mm) - 1, Number(dd))
  },

  getCpfClean: (cpf: string): string => {
    return cpf.replace(/[^\d]/g, "")
  },

  getCpfFormatted: (cpf: string): string => {
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
  },

  unMaskMoney: (value: string) => {
    return parseFloat(value.toString().replace(/\./g, "").replace(/,/g, "."))
  }
}
