import { Info } from './styles'

export function CardInfo() {
  return (
    <Info>
      <div>
        <ul>
          <li><strong>CANDIDATO</strong></li>
          <li>Documento Pessoal (RG, Certidão de Nascimento ou CNH)</li>
          <li>CPF</li>
          <li>Comprovante de Endereço</li>
        </ul>
        <ul>
          <li><strong>RESPONSÁVEL (para menores de 18 anos)</strong></li>
          <li>Documento Pessoal (RG, Certidão de Nascimento ou CNH)</li>
          <li>CPF</li>
          <li>Comprovante de Endereço</li>
        </ul>
      </div>
      <p>* Será aceito somente comprovante de endereço dos últimos 3 meses considerando a data atual.</p>
      <p>* Formatos permitidos:  JPG, JPEG, PNG ou PDF</p>
    </Info>
  )
}
