import { ReactNode } from 'react'
import * as S from './_styles'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'

interface ModalTitleProps {
  children: ReactNode;
  handleClose: () => void;
}

export function ModalTitle({ children, handleClose }: ModalTitleProps) {
  return (
    <S.ModalTitle disableTypography>
      <Typography variant="h6">{children}</Typography>
      <IconButton aria-label="close" onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    </S.ModalTitle>
  )
}
