import styled from 'styled-components'

export const Container = styled.div`
  .MuiInput-root {
    width: 100%;
  }

  .MuiFormLabel-root {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
  }

  span.error {
    font-size: .8rem;
    color: #f44336;
  }
`
