import styled from 'styled-components'

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary);
  border: 0;
  border-radius: 3rem;
  color: #fff;
  font-size: 1rem;
  padding: .4rem 1.4rem;

  svg {
    margin-right: .4rem;
  }

  transition: filter .2s;
  &:hover {
    filter: brightness(.8);
  }
`

export const ErrorMessage = styled.span`
    display: block;
    margin-top: 1rem;
    padding: .8rem;
    font-size: .9rem;
    border-left: .4rem solid #f44336;
    background-color: #ffcdd2;
`
