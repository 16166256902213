import { useState, useContext } from 'react'
import { ModalDefault as Modal } from '../base/default'
import { ModalContent } from '../base/content'
import { ModalTitle } from '../base/title'
import { Info } from './styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import AssignmentIcon from '@material-ui/icons/Assignment'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import { SubscriptionProvider, useSubscription } from '../../../hooks/subscription'
import { courseService } from '../../../services/course'
import {
  FormDataCandidate,
  CandidatoViewModel,
  InscricaoViewModel,
  TurmaViewModel
} from '../../../_types'

interface ModalFilterCityProps {
  open: boolean;
  handleClose: () => void;
  //handleClose: () => string;
  unidadeId: string;
  setUnidadeId: any;
  setModalFilterCityIsOpen: any;
}

export function ModalFilterCity({ open, handleClose, unidadeId, setUnidadeId, setModalFilterCityIsOpen }: ModalFilterCityProps) {

  const [selectedOption, setSelectedOption] = useState<String>();

  // This function is triggered when the select changes
  const selectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  const value = event.target.value;
  //const valueContext = useContext(SubscriptionProvider);
    setSelectedOption(value);
    setUnidadeId(value);
    setModalFilterCityIsOpen(false);
    
    return (value);

  }

  function refreshPage() {
    window.location.reload(true);
  }
 
  return (
    <Modal
      fullWidth
      maxWidth="md"
      open={open}
      fullScreen={true}
      //handleClose={handleClose}
      handleClose={() => { }}
    >
      <ModalContent dividers={false}>
        <Info>
          <div>
            <h2>
              <label><strong>Selecione a cidade desejada: </strong></label>
              <select name="unidade" id="unidade" onChange={selectChange}>
				<option value="0"/>
                {/*<option value="28">Alexânia</option>*/}
                <option value="8">Anápolis</option>
                <option value="23">Aparecida de Goiânia</option>
                {/*<option value="26">Aparecida de Goiânia II</option>*/}
                <option value="12">Caldas Novas</option>
                <option value="11">Catalão</option>
                <option value="18">Ceres</option>
                <option value="7">Goiânia - St. Santa Genoveva</option>
                <option value="6">Goiânia - St. Aeroporto</option>
                {/*<option value="22">Ipamerí</option>*/}
                {/*<option value="9">Iporá</option>*/}
                {/*<option value="27">Itaberaí</option>*/}
                <option value="10">Itumbiara</option>
                <option value="14">Jataí</option>
                <option value="15">Luziânia</option>
                <option value="21">Mineiros</option>
                {/*<option value="19">Porangatu</option>*/}
                <option value="20">Quirinópolis</option>
                {/*<option value="16">Redenção</option>*/}
                <option value="13">Rio Verde</option>
                <option value="29">Trindade</option>
              </select>
              {/*{selectedOption && <h2>{selectedOption}</h2>}*/}
            </h2>
          </div>
        </Info>
      </ModalContent>
    </Modal >
  )
}
