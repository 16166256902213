import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import { Router } from './router'
import { AppProvider } from './hooks'
import { HttpIntercept } from './components/http-intercept'

import { GlobalStyle } from './styles/global'

function App() {
  return (
    <AppProvider>
      <HttpIntercept />
      <Router />
      <GlobalStyle />
      <ToastContainer />
    </AppProvider>
  );
}

export default App
