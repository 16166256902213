import { useState } from 'react'
import Select, { SelectProps } from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import { FieldErrors } from 'react-hook-form'
import { Container } from './styles'
import { TSelectOption } from '../../../_types'

interface ISelectProps extends SelectProps {
  options: TSelectOption[];
  label?: string;
  invalid?: FieldErrors;
}

export function InputSelect({ options, label, name, invalid, ...rest }: ISelectProps) {
  const [focused, setFocused] = useState(false)

  const setFocusedOn = () => {
    if (!focused) setFocused(true)
  }

  const setFocusedOff = () => {
    if (focused) setFocused(false)
  }

  return (
    <Container>
      {!!label &&
        <InputLabel
          htmlFor={name}
          error={!!invalid}
          focused={focused}>
          {label}
        </InputLabel>}
      <Select
        id={name}
        name={name}
        onFocus={setFocusedOn}
        onBlur={setFocusedOff}
        native
        autoWidth
        error={!!invalid}
        {...rest}
      >
        <option aria-label="None" value="-1" />
        {options.map(({ value, label }) => (
          <option key={value} value={value}>{label}</option>
        ))}
      </Select>
      { invalid && <span className="error">{invalid.message}</span>}
    </Container >
  )
}
