import { Info } from './styles'

export function CardInfoAtention() {
  return (
    <Info>
      <div>
        <strong>
          <h3>ATENÇÃO</h3>
          <ul>
            Para os Cursos Técnicos e Qualificações Técnicas, o candidato deverá comparecer, antes do início do curso na unidade em que fez a sua solicitação e apresentar todos os documentos originais para conferência e também o documento de Registro Civil (certidão de nascimento ou casamento) e 1 (uma) foto ¾ (recente);
            A não apresentação dos documentos exigidos pelo candidato, será considerado desistência da solicitação de matrícula.
          </ul>
        </strong>
      </div>
    </Info>
  )
}
