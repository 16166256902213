import { CursoViewModel, TurmaViewModel } from '../_types'
import { psgApi, TResponse } from './http'

export const courseService = {  
  getAll: async (): Promise<TResponse<CursoViewModel[]>> => {
    try {
      const response = await psgApi.get(`curso/all/turmas?situacao=true`, { activeSpinner: true })

      if (response && response.status === 400) {
        throw new Error(response.data.content[0].message)
      }

      if (!response || response.status > 299) {
        throw new Error('Tivemos um problema para buscar os cursos disponíveis. Se o erro persistir, entre em contato com o Senac.')
      }

      return {
        succeeded: true,
        data: response.data,
        message: null
      }
    } catch (error) {
      return {
        succeeded: false,
        data: null,
        message: error.message
      }
    }
  },

  getUnidadeById: async (unidadeId: string): Promise<TResponse<CursoViewModel[]>> => {
    try {
      const response = await psgApi.get(`curso/all/turmas?situacao=true&unidadeid=${unidadeId}`, { activeSpinner: true})

      if (response && response.status === 400) {
        throw new Error(response.data.content[0].message)
      }

      if (!response || response.status > 299) {
        throw new Error('Tivemos um problema para buscar os cursos disponíveis. Se o erro persistir, entre em contato com o Senac.')
      }

      return {
        succeeded: true,
        data: response.data,
        message: null
      }
    } catch (error) {
      return {
        succeeded: false,
        data: null,
        message: error.message
      }
    }
  },

  getTurmas: async (courseId: number): Promise<TResponse<TurmaViewModel[]>> => {
    try {
      const response = await psgApi
        .get(`/curso/${courseId}/turmas?situacao=true`, {
          activeSpinner: true
        })

      if (response && response.status === 400) {
        throw new Error(response.data.content[0].message)
      }

      if (!response || response.status > 299) {
        throw new Error('Tivemos um problema para buscar as turmas deste curso. Se o erro persistir, entre em contato com o Senac.')
      }

      return {
        succeeded: true,
        data: response.data,
        message: null
      }
    } catch (error) {
      return {
        succeeded: false,
        data: null,
        message: error.message
      }
    }
  },
}
