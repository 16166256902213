import { TemplateDocumentViewModel } from '../components/../_types'
import { psgApi, TResponse } from './http'

export const templatesDocumentService = {
  get: async (type: string, idSubscription: number): Promise<TResponse<TemplateDocumentViewModel>> => {
    try {
      const response = await psgApi
        .get(`/template-documento/${type}/inscricao/${idSubscription}`, {
          activeSpinner: true
        })

      if (response && response.status === 400) {
        throw new Error(response.data.content[0].message)
      }

      if (!response || response.status > 299) {
        throw new Error('Tivemos um problema para exibir o termo de compromisso. Se o erro persistir, entre em contato com o Senac.')
      }

      return {
        succeeded: true,
        data: response.data,
        message: null
      }
    } catch (error) {
      return {
        succeeded: false,
        data: null,
        message: error.message
      }
    }
  }
}
