import * as yup from 'yup'
import {
  enumEscolaridade,
  enumTipoDocumento,
  enumEstadoCivil,
  enumTipoDeficiencia,
  ESTADO_CIVIL_NAO_INFORMADO,
  SIM, NAO, TRUE, FALSE
} from '../../../config/enums'

const validOptionsEscolaridade = enumEscolaridade.map(i => i.value)
const validOptionsEstadoCivil = enumEstadoCivil.map(i => i.value)
const validOptionsTipoDocumento = enumTipoDocumento.map(i => i.value)
const validOptionsTipoDeficiencia = enumTipoDeficiencia.map(i => i.value)

export const schemaCandidateValidation = (disabled: boolean) => yup.object().shape({
  turmaId: yup.number()
    .when([], {
      is: () => disabled,
      then: yup.number().notRequired(),
      otherwise: yup.number()
        .positive('Escolha uma turma')
        .required('Escolha uma turma'),
    }),
  cpf: yup.string()
    .when([], {
      is: () => disabled,
      then: yup.string().notRequired(),
      otherwise: yup.string()
        .required('Informe o CPF do candidato'),
    }),
  nome: yup.string()
    .when([], {
      is: () => disabled,
      then: yup.string().notRequired(),
      otherwise: yup.string()
        .required('Informe o nome completo'),
    }),
  nomeMae: yup.string()
    .when([], {
      is: () => disabled,
      then: yup.string().notRequired(),
      otherwise: yup.string()
        .required('Informe o nome da mãe'),
    }),
  sexo: yup.string()
    .when([], {
      is: () => disabled,
      then: yup.string().notRequired(),
      otherwise: yup.string()
        .length(1, 'Informe o gênero')
        .required('Informe o gênero'),
    }),
  estadoCivil: yup.number()
    .oneOf(validOptionsEstadoCivil, 'Informe o estado civil')
    .required('Informe o estado civil'),
  outroEstadoCivil: yup.string()
    .when('estadoCivil', (estadoCivil: number, schema: any) => {
      if (estadoCivil === ESTADO_CIVIL_NAO_INFORMADO) {
        return schema.required('Especifique o estado civil');
      }
    }),
  dataNascimento: yup.string()
    .when([], {
      is: () => disabled,
      then: yup.string().notRequired(),
      otherwise: yup.string()
        .required('Informe a data de nascimento'),
    }),
  tipoDocumento: yup.number()
    .when([], {
      is: () => disabled,
      then: yup.number().notRequired(),
      otherwise: yup.number()
        .oneOf(validOptionsTipoDocumento, 'Informe o documento usado no cadastro')
        .required('Informe o documento usado no cadastro'),
    }),
  numeroDocumento: yup.string()
    .when([], {
      is: () => disabled,
      then: yup.string().notRequired(),
      otherwise: yup.string()
        .required('Informe o número do documento'),
    }),
  naturalidade: yup.string()
    .when([], {
      is: () => disabled,
      then: yup.string().notRequired(),
      otherwise: yup.string()
        .required('Informe a cidade onde nasceu'),
    }),
  nacionalidade: yup.string()
    .when([], {
      is: () => disabled,
      then: yup.string().notRequired(),
      otherwise: yup.string()
        .required('Informe a nacionalidade'),
    }),
  escolaridade: yup.number()
    .when([], {
      is: () => disabled,
      then: yup.number().notRequired(),
      otherwise: yup.number()
        .oneOf(validOptionsEscolaridade, 'Informe o nível de escolaridade')
        .required('Informe o nível de escolaridade'),
    }),
  tipoDeficiencia: yup.number()
    .oneOf(validOptionsTipoDeficiencia, 'Informe se possui deficiência')
    .required('Informe se possui deficiência'),
  trabalha: yup.string()
    .when([], {
      is: () => disabled,
      then: yup.string().notRequired(),
      otherwise: yup.string()
        .oneOf([SIM, NAO], 'Informe se trabalha atualmente')
        .required('Informe se trabalha atualmente'),
    }),
  profissao: yup.string()
    .when('trabalha', (trabalha: any, schema: any) => {
      if (trabalha === SIM) {
        return schema.required('Especifique a profissão');
      }
    }),
  estudanteRedePublica: yup.string()
    .oneOf([TRUE, FALSE], 'Informe se é estudante da rede pública')
    .required('Informe se é estudante da rede pública'),
  participouPSG: yup.string()
    .oneOf([SIM, NAO], 'Informe se já participou do PSG')
    .required('Informe se já participou do PSG'),
  bolsistaProBem: yup.string()
    .oneOf([SIM, NAO], 'Informe se participa de algum programa/projeto da OVG')
    .required('Informe se participa de algum programa/projeto da OVG'),
})
