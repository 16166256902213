import * as yup from 'yup'
import {
  enumParentescoResponsavel,
  enumTipoDocumento,
  enumEstadoCivil,
  enumTipoDeficiencia,
  ESTADO_CIVIL_NAO_INFORMADO,
  SIM, NAO
} from '../../../config/enums'

const validOptionsTipoDocumento = enumTipoDocumento.map(i => i.value)
const validOptionsEstadoCivil = enumEstadoCivil.map(i => i.value)
const validOptionsParentesco = enumParentescoResponsavel.map(i => i.value)
const validOptionsTipoDeficiencia = enumTipoDeficiencia.map(i => i.value)

export const schemarResponsibleValidation = (disabled: boolean) => yup.object().shape({
  cpf: yup.string()
    .when([], {
      is: () => disabled,
      then: yup.string().notRequired(),
      otherwise: yup.string()
        .required('Informe o CPF do responsável'),
    }),
  nome: yup.string()
    .when([], {
      is: () => disabled,
      then: yup.string().notRequired(),
      otherwise: yup.string()
        .required('Informe o nome completo'),
    }),
  nomeMae: yup.string()
    .when([], {
      is: () => disabled,
      then: yup.string().notRequired(),
      otherwise: yup.string()
        .required('Informe o nome da mãe'),
    }),
  sexo: yup.string()
    .length(1, 'Informe o gênero')
    .required('Informe o gênero'),
  estadoCivil: yup.number()
    .oneOf(validOptionsEstadoCivil, 'Informe o estado civil')
    .required('Informe o estado civil'),
  outroEstadoCivil: yup.string()
    .when('estadoCivil', (estadoCivil: number, schema: any) => {
      if (estadoCivil === ESTADO_CIVIL_NAO_INFORMADO) {
        return schema.required('Especifique o estado civil');
      }
    }),
  grauParentescoTitular: yup.number()
    .oneOf(validOptionsParentesco, 'Informe o grau de parentesco')
    .required('Informe o grau de parentesco'),
  dataNascimento: yup.string()
    .when([], {
      is: () => disabled,
      then: yup.string().notRequired(),
      otherwise: yup.string()
        .required('Informe a data de nascimento'),
    }),
  tipoDocumento: yup.number()
    .oneOf(validOptionsTipoDocumento, 'Informe o documento usado no cadastro')
    .required('Informe o documento usado no cadastro'),
  numeroDocumento: yup.string()
    .required('Informe o número do documento'),
  naturalidade: yup.string()
    .required('Informe a cidade onde nasceu'),
  nacionalidade: yup.string()
    .required('Informe a nacionalidade'),
  tipoDeficiencia: yup.number()
    .oneOf(validOptionsTipoDeficiencia, 'Informe se possui deficiência')
    .required('Informe se possui deficiência'),
  trabalha: yup.string()
    .when([], {
      is: () => disabled,
      then: yup.string().notRequired(),
      otherwise: yup.string()
        .oneOf([SIM, NAO], 'Informe se trabalha atualmente')
        .required('Informe se trabalha atualmente'),
    }),
  profissao: yup.string()
    .when('trabalha', (trabalha: any, schema: any) => {
      if (trabalha === SIM) {
        return schema.required('Especifique a profissão');
      }
    }),
  participouPSG: yup.string()
    .oneOf([SIM, NAO], 'Informe se já participou do PSG')
    .required('Informe se já participou do PSG'),
})
