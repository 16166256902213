import { InscricaoViewModel } from '../_types'
import { psgApi, TResponse } from './http'

export const subscriptionService = {
  save: async (subscription: InscricaoViewModel) => {
    if (subscription.id) {
      return await _update(subscription)
    } else {
      return await _create(subscription)
    }
  },

  get: async (subscriptionId: number): Promise<TResponse<InscricaoViewModel>> => {
    try {
      const response = await psgApi
        .get(`/inscricao/${subscriptionId}`, {
          activeSpinner: true
        })

      if (response && response.status === 400) {
        throw new Error(response.data.content[0].message)
      }

      if (!response || response.status > 299) {
        throw new Error('Tivemos um problema para buscar a inscrição. Se o erro persistir, entre em contato com o Senac.')
      }

      return {
        succeeded: true,
        data: response.data,
        message: null
      }
    } catch (error) {
      return {
        succeeded: false,
        data: null,
        message: error.message
      }
    }
  },

  getAll: async (): Promise<TResponse<InscricaoViewModel[]>> => {
    try {
      const response = await psgApi
        .get('/inscricao/inscricao-pessoa-logada')

      if (response && response.status === 400) {
        throw new Error(response.data.content[0].message)
      }

      if (!response || response.status > 299) {
        throw new Error('Tivemos um problema para buscar as inscrições. Se o erro persistir, entre em contato com o Senac.')
      }

      return {
        succeeded: true,
        data: response.data,
        message: null
      }
    } catch (error) {
      return {
        succeeded: false,
        data: null,
        message: error.message
      }
    }
  },

  documents: async (files: FormData, subscriptionId: number) => {
    try {
      const response = await psgApi
        .put(`/inscricao/${subscriptionId}/documentos`, files, {
          activeSpinner: true
        })

      if (response && response.status === 400) {
        throw new Error(response.data.content[0].message)
      }

      if (!response || response.status > 299) {
        throw new Error('Tivemos um problema para gravar a inscrição. Se o erro persistir, entre em contato com o Senac.')
      }

      return {
        succeeded: true,
        data: response.data,
        message: null
      }
    } catch (error) {
      return {
        succeeded: false,
        data: null,
        message: error.message
      }
    }
  },

  cancel: async (subscriptionId: number): Promise<TResponse<InscricaoViewModel>> => {
    try {
      const response = await psgApi
        .put(`/inscricao/${subscriptionId}/cancelar`, null, { activeSpinner: true })

      if (response && response.status === 400) {
        throw new Error(response.data.content[0].message)
      }

      if (!response || response.status > 299) {
        throw new Error('Tivemos um problema para cancelar a inscrição. Se o erro persistir, entre em contato com o Senac.')
      }

      return {
        succeeded: true,
        data: response.data,
        message: null
      }
    } catch (error) {
      return {
        succeeded: false,
        data: null,
        message: error.message
      }
    }
  },

}

const _create = async (subscription: InscricaoViewModel): Promise<TResponse<InscricaoViewModel>> => {
  try {
    const response = await psgApi
      .post('/inscricao', subscription, {
        activeSpinner: true
      })

    if (response && response.status === 400) {
      throw new Error(response.data.content[0].message)
    }

    if (!response || response.status > 299) {
      throw new Error('Tivemos um problema para gravar a inscrição. Se o erro persistir, entre em contato com o Senac.')
    }

    return {
      succeeded: true,
      data: response.data,
      message: null
    }
  } catch (error) {
    return {
      succeeded: false,
      data: null,
      message: error.message
    }
  }
}

const _update = async (subscription: InscricaoViewModel): Promise<TResponse<InscricaoViewModel>> => {
  try {
    const response = await psgApi
      .put(`/inscricao/${subscription.id}`, subscription, {
        activeSpinner: true
      })

    if (response && response.status === 400) {
      throw new Error(response.data.content[0].message)
    }

    if (!response || response.status > 299) {
      throw new Error('Tivemos um problema para gravar a inscrição. Se o erro persistir, entre em contato com o Senac.')
    }

    return {
      succeeded: true,
      data: response.data,
      message: null
    }
  } catch (error) {
    return {
      succeeded: false,
      data: null,
      message: error.message
    }
  }
}
