import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Content } from './styles'
import { useSubscription } from '../../hooks/subscription'
import { ModalStartSubscription } from '../modal/start-subscription'
import { CardCourse } from './card-course'
import { CursoViewModel } from '../../_types'

export function ListCourses() {
  const history = useHistory()
  const [modalSubscriptionIsOpen, setModalSubscriptionIsOpen] = useState(false)
  const { listCourses, setSelectedCourse, resetSubscriptionState } = useSubscription()

  const handleOpenModalSubscription = (course: CursoViewModel) => {
    setSelectedCourse(course)
    setModalSubscriptionIsOpen(true)
  }

  const handleCloseModalSubscription = () => {
    resetSubscriptionState()
    setModalSubscriptionIsOpen(false)
  }

  const handleClickOkModalSubscription = () => {
    history.push('/inscricao')
    setModalSubscriptionIsOpen(false)
  }

  return (
    <Content>
      {
        listCourses.map(course => (
          <CardCourse
            key={course.id}
            course={course}
            handleClickSubscription={handleOpenModalSubscription}
          />
        ))
      }
      <ModalStartSubscription
        open={modalSubscriptionIsOpen}
        handleClose={handleCloseModalSubscription}
        handleClickOk={handleClickOkModalSubscription}
      />
    </Content>
  )
}
