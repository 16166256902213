import { toast } from 'react-toastify'
import { ModalDefault as Modal } from '../base/default'
import { ModalActions } from '../base/actions'
import { ModalContent } from '../base/content'
import { ModalTitle } from '../base/title'
import { subscriptionService } from '../../../services/subscription'
import { useSubscription } from '../../../hooks/subscription'
import { Button } from './styles'

interface ModalCancelSubscriptionProps {
  open: boolean;
  idSubscription: number | null;
  handleClose: () => void;
}

export function ModalCancelSubscription({ open, idSubscription, handleClose }: ModalCancelSubscriptionProps) {
  const { reloadLists } = useSubscription()

  const handleClickYes = async () => {
    if (!idSubscription) {
      toast.warn('Número de inscrição inválido.')
      return
    }

    const { succeeded, message } = await subscriptionService.cancel(idSubscription)
    if (succeeded) {
      toast.success('Inscrição cancelada com sucesso.')
    } else {
      toast.error(message)
      return
    }

    reloadLists()
    handleClose()
  }

  return (
    <Modal
      fullWidth
      maxWidth="sm"
      open={open}
      handleClose={handleClose}
    >
      <ModalTitle handleClose={handleClose}>
        Cancelar Inscrição
      </ModalTitle>
      <ModalContent dividers={false}>
        Deseja realmente cancelar essa inscrição?
      </ModalContent>
      <ModalActions>
        <Button
          className="btn-not"
          onClick={handleClose}
        >
          Não
        </Button>
        <Button
          className="btn-yes"
          onClick={handleClickYes}
        >
          Sim
        </Button>
      </ModalActions>
    </Modal>
  )
}
