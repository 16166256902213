import { ReactNode } from 'react'
import MuiDialogContent from '@material-ui/core/DialogContent'

interface ModalContentProps {
  children: ReactNode;
  dividers: boolean;
}

export function ModalContent({ children, dividers }: ModalContentProps) {
  return (
    <MuiDialogContent dividers={dividers}>
      {children}
    </MuiDialogContent>
  )
}
