import styled from 'styled-components'
import Tooltip from '@material-ui/core/Tooltip'
import withStyles from '@material-ui/core/styles/withStyles'

export const TooltipCustom = withStyles({
  tooltip: {
    fontSize: '.9rem'
  }
})(Tooltip)

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 320px;
  height: 100%;
  background-color: #fff;
  border-radius: 4px;
  padding: 1rem;
  text-decoration: none;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.12);
  transition: all 0.3s ease-out;

  h3 {
    span {
      font-size: 1.3rem;
    }

    strong {
      font-size: 1.3rem;
      line-height: 1.5rem;
    }

    p {
      display: block;
      font-size: .9rem;
      font-weight: 400;
      text-align: center;
    }

    div.info-turma {
      span {
      font-size: .8rem;
      margin-top: .5rem;
      }

      strong {
        font-size: .8rem;
        line-height: 1rem;
      }
    }
  }

  span {
    display: block;
    font-weight: 400;
    text-align: center;
  }

  strong {
    display: block;
    font-weight: 600;
    text-align: center;
    line-height: 2rem;
  }

  hr {
    width: 100%;
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.1);
  }

  > div.info {
    span {
      font-size: .9rem;
      margin-top: .5rem;

      &:first-child {
        margin-top: unset;
      }
    }

    strong {
      font-size: .9rem;
      line-height: 1rem;
    }
  }

  > div.actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: #5a5858;
    padding: .5rem 0;

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }

    button {
      display: flex;
      font-size: .9rem;
      align-items: center;
      padding: .2rem .4rem;
      border-radius: 3rem;
      background: none;
      border: 0;
      transition: all .2s;
    }

    button.edit {
      color: #55c539;
    }

    button.cancel {
      color: #da0606;
    }

    button.msg {
      color: #6b6a6a;
    }
  }
`

interface TagProps {
  color: string;
}

export const Tag = styled.strong<TagProps>`
  text-align: center;
  padding: 0.3rem 0.6rem;
  background: ${props => props.color};
  font-weight: 400;
  color: var(--text);
  border-radius: .2rem;
`
