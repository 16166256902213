/**
 * ENUM | OPÇÕES DE MORADIA
 */
export const MORADIA_PROPRIA = 1
export const MORADIA_ALUGADA = 2
export const MORADIA_CEDIDA = 3

export const enumMoradia = [
  { value: MORADIA_PROPRIA, label: 'Própria' },
  { value: MORADIA_ALUGADA, label: 'Alugada' },
  { value: MORADIA_CEDIDA, label: 'Cedida' },
]

/**
 * ENUM | OPÇÕES DE PARENTESCO
 */
export const PARENTESCO_RENDA_PAI = 1
export const PARENTESCO_RENDA_PADASTRO = 2
export const PARENTESCO_RENDA_MAE = 3
export const PARENTESCO_RENDA_MADASTRA = 4
export const PARENTESCO_RENDA_CONJUGE = 5
export const PARENTESCO_RENDA_COMPANHEIRO = 6
export const PARENTESCO_RENDA_FILHO = 7
export const PARENTESCO_RENDA_ENTEADO = 8
export const PARENTESCO_RENDA_IRMAO = 9
export const PARENTESCO_RENDA_AVO = 10
export const PARENTESCO_RENDA_CANDIDATO = 11
export const PARENTESCO_RENDA_OUTROS = 12

export const enumParentesco = [
  { value: PARENTESCO_RENDA_PAI, label: 'Pai' },
  { value: PARENTESCO_RENDA_PADASTRO, label: 'Padrasto' },
  { value: PARENTESCO_RENDA_MAE, label: 'Mãe' },
  { value: PARENTESCO_RENDA_MADASTRA, label: 'Madrasta' },
  { value: PARENTESCO_RENDA_CONJUGE, label: 'Cônjuge' },
  { value: PARENTESCO_RENDA_COMPANHEIRO, label: 'Companheiro(a)' },
  { value: PARENTESCO_RENDA_FILHO, label: 'Filho(a)' },
  { value: PARENTESCO_RENDA_ENTEADO, label: 'Enteado(a)' },
  { value: PARENTESCO_RENDA_IRMAO, label: 'Irmão(ã)' },
  { value: PARENTESCO_RENDA_AVO, label: 'Avô(a)' },
  { value: PARENTESCO_RENDA_CANDIDATO, label: 'Candidato' },
  { value: PARENTESCO_RENDA_OUTROS, label: 'Outros' },
]

/**
 * ENUM | OPÇÕES DE FONTE DE RENDA
 */
export const FONTE_RENDA_APOSENTADORIA = 1
export const FONTE_RENDA_PENSAO = 2
export const FONTE_RENDA_SALARIO = 3
export const FONTE_RENDA_ALUGUEIS = 4
export const FONTE_RENDA_INVESTIMENTOS = 5
export const FONTE_RENDA_PRO_LABORE = 6
export const FONTE_RENDA_SEM_REMUNERACAO = 7

export const enumFonteRenda = [
  { value: FONTE_RENDA_APOSENTADORIA, label: "Aposentadoria" },
  { value: FONTE_RENDA_PENSAO, label: "Pensão" },
  { value: FONTE_RENDA_SALARIO, label: "Salário" },
  { value: FONTE_RENDA_ALUGUEIS, label: "Aluguéis" },
  { value: FONTE_RENDA_INVESTIMENTOS, label: "Investimentos" },
  { value: FONTE_RENDA_PRO_LABORE, label: "Pró-labore" },
  { value: FONTE_RENDA_SEM_REMUNERACAO, label: "Sem remuneração" }
]
