import styled from 'styled-components'

export const Message = styled.div`
  width: 100%;
  background: #efecec;
  padding: .5rem;
  border-left: .4rem solid #dedede;
  margin-top: .6rem;

  &:first-child {
    margin-top: 0;
  }

  strong {
    display: block;
    font-weight: 500;
    font-size: 1rem;
  }

  span {
    font-size: .8rem;
    font-weight: 400;
    color: #6d6d6d;
  }
`

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary);
  border: 0;
  border-radius: 3rem;
  color: #fff;
  font-size: 1.1rem;
  padding: .4rem 1.4rem;
  margin: .6rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

  transition: filter .2s;
  &:hover {
    filter: brightness(.8);
  }
`
