import styled from 'styled-components'

export const Info = styled.div`
  h3 {
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.0075em;
    margin-bottom: 1.2rem;
  }

  p {
    display: block;
    padding: .8rem;
    font-size: .9rem;
    border-left: .4rem solid #ffeb3b;
    background-color: #fff9c4;
    margin-top: 1rem;
  }

  span {
    color: #ef5350;
  }

  ul {
    padding: .5rem 1rem;

    li:first-child {
      list-style: none;
      margin-bottom: .4rem;
    }

    li {
      font-size: .9rem;

      & + li {
        margin-left: 2rem;
      }
    }
  }
`

export const ButtonContinue = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary);
  border: 0;
  border-radius: 3rem;
  color: #fff;
  font-size: 1.1rem;
  padding: .4rem 1.4rem;
  margin: .6rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

  transition: filter .2s;
  &:hover {
    filter: brightness(.8);
  }
`
