import { useState } from 'react'
import { Link } from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar'
import Popover from '@material-ui/core/Popover'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import Divider from '@material-ui/core/Divider'
import { authService } from '../../../services/auth'
import { useAuth } from '../../../hooks/auth'

import { ButtonAvatar, PopoverContent } from './styles'

export function MenuLarge() {
  const { authState } = useAuth()
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorElement(null)
  }

  const open = Boolean(anchorElement)

  return (
    <>
      <Link to="/processos-seletivos">
        Processos Seletivos
          </Link>
      <Link to="/minhas-inscricoes">
        Minhas Inscrições
          </Link>
      <ButtonAvatar
        type="button"
        onClick={handleClick}
      >
        <Avatar />
      </ButtonAvatar>
      <Popover
        open={open}
        anchorEl={anchorElement}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <PopoverContent>
          <p>{authState.user.email || ''}</p>
          <Divider light />
          <button
            type="button"
            onClick={() => authService.redirectToLogout()}
          >
            Desconectar <ExitToAppIcon />
          </button>
        </PopoverContent>
      </Popover>
    </>
  )
}
