import { InfoFamiliaViewModel } from '../_types'
import { psgApi, TResponse } from './http'

export const infoFamiliaService = {
  save: async (infoFamilia: InfoFamiliaViewModel): Promise<TResponse<InfoFamiliaViewModel>> => {
    try {
      const response = await psgApi.post('/info-familiar', infoFamilia, { activeSpinner: true })

      if (response && response.status === 400) {
        throw new Error(response.data.content[0].message)
      }

      if (!response || response.status > 299) {
        throw new Error('Tivemos um problema para gravar as informações de composição familiar. Se o erro persistir, entre em contato com o Senac.')
      }

      return {
        succeeded: true,
        data: response.data,
        message: null
      }
    } catch (error) {
      return {
        succeeded: false,
        data: null,
        message: error.message
      }
    }
  },

  getDefault: async (): Promise<TResponse<InfoFamiliaViewModel>> => {
    try {
      const response = await psgApi.get('/info-familiar/pessoa-logada', { activeSpinner: true })

      if (response && response.status === 400) {
        throw new Error(response.data.content[0].message)
      }

      if (!response || response.status > 299) {
        throw new Error('Tivemos um problema para obter as informações de composição familiar. Se o erro persistir, entre em contato com o Senac.')
      }

      return {
        succeeded: true,
        data: response.data,
        message: null
      }
    } catch (error) {
      return {
        succeeded: false,
        data: null,
        message: error.message
      }
    }
  }
}
