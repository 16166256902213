/**
 * ENUM | OPÇÕES DE GENERO
 */
export const GENERO_MASCULINO = 'M'
export const GENERO_FEMININO = 'F'

export const enumGenero = [
  { value: GENERO_MASCULINO, label: 'Masculino' },
  { value: GENERO_FEMININO, label: 'Feminino' }
]

/**
 * ENUM | OPÇÕES DE ESTADO CIVIL
 */
export const ESTADO_CIVIL_NAO_INFORMADO = 0
export const ESTADO_CIVIL_SOLTEIRO = 1
export const ESTADO_CIVIL_CASADO = 2
export const ESTADO_CIVIL_VIUVO = 3
export const ESTADO_CIVIL_DIVORCIADO = 4
export const ESTADO_CIVIL_UNIAO_ESTAVEL = 5
export const ESTADO_CIVIL_SEPARADO = 6

export const enumEstadoCivil = [
  { value: ESTADO_CIVIL_SOLTEIRO, label: 'Solteiro' },
  { value: ESTADO_CIVIL_CASADO, label: 'Casado' },
  { value: ESTADO_CIVIL_VIUVO, label: 'Viuvo' },
  { value: ESTADO_CIVIL_DIVORCIADO, label: 'Divorciado' },
  { value: ESTADO_CIVIL_UNIAO_ESTAVEL, label: 'União Estável' },
  { value: ESTADO_CIVIL_SEPARADO, label: 'Separado' },
  { value: ESTADO_CIVIL_NAO_INFORMADO, label: 'Outros' },
]

/**
 * ENUM | OPÇÕES DE TIPOS DOCUMENTOS
 */
export const TIPO_DOCUMENTO_PESSOA_RG = 1
export const TIPO_DOCUMENTO_PESSOA_CNH = 2
export const TIPO_DOCUMENTO_PESSOA_CARTEIRA_REGISTRO_PROFISSIONAL = 3
export const TIPO_DOCUMENTO_PESSOA_CTPS = 4
export const TIPO_DOCUMENTO_PESSOA_CERTIDAO_NASCIMENTO = 5
export const TIPO_DOCUMENTO_PESSOA_CARTEIRA_RESERVISTA = 6
export const TIPO_DOCUMENTO_PESSOA_PASSAPORTE = 7
export const TIPO_DOCUMENTO_PESSOA_REGISTRO_ESTRANGEIRO = 8

export const enumTipoDocumento = [
  { value: TIPO_DOCUMENTO_PESSOA_RG, label: 'Carteira de Identidade' },
  { value: TIPO_DOCUMENTO_PESSOA_CNH, label: 'Carteira Nacional de Habilitação' },
  { value: TIPO_DOCUMENTO_PESSOA_CARTEIRA_REGISTRO_PROFISSIONAL, label: 'Carteira de Registro Profissional' },
  { value: TIPO_DOCUMENTO_PESSOA_CTPS, label: 'Carteira de Trabalho' },
  { value: TIPO_DOCUMENTO_PESSOA_CERTIDAO_NASCIMENTO, label: 'Certidão de Nascimento' },
  { value: TIPO_DOCUMENTO_PESSOA_CARTEIRA_RESERVISTA, label: 'Certificado de Reservista' },
  { value: TIPO_DOCUMENTO_PESSOA_PASSAPORTE, label: 'Passaporte' },
  { value: TIPO_DOCUMENTO_PESSOA_REGISTRO_ESTRANGEIRO, label: 'Registro Nacional de Estrangeiro' },
]

/**
 * ENUM | OPÇÕES DE ESCOLARIDADE
 */
export const SEM_INSTRUCAO = 1
export const ENSINO_FUNDAMENTAL_INCOMPLETO = 2
export const ENSINO_FUNDAMENTAL_COMPLETO = 3
export const ENSINO_MEDIO_INCOMPLETO = 4
export const ENSINO_MEDIO_COMPLETO = 5
export const ENSINO_SUPERIOR_INCOMPLETO = 6
export const ENSINO_SUPERIOR_COMPLETO = 7
export const MESTRADO = 8
export const DOUTORADO = 9
export const POS_DOUTORADO = 10
export const ESPECIALIZACAO_INCOMPLETO = 11
export const ESPECIALIZACAO_COMPLETO = 12
export const MESTRADO_INCOMPLETO = 13
export const DOUTORADO_INCOMPLETO = 14
export const POS_DOUTORADO_INCOMPLETO = 15
export const ENSINO_TECNICO_INCOMPLETO = 16
export const ENSINO_TECNICO_COMPLETO = 17

export const enumEscolaridade = [
  { value: SEM_INSTRUCAO, label: 'Sem Instrução' },
  { value: ENSINO_FUNDAMENTAL_INCOMPLETO, label: 'Ensino Fundamental Incompleto' },
  { value: ENSINO_FUNDAMENTAL_COMPLETO, label: 'Ensino Fundamental Completo' },
  { value: ENSINO_MEDIO_INCOMPLETO, label: 'Ensino Médio Incompleto' },
  { value: ENSINO_MEDIO_COMPLETO, label: 'Ensino Médio Completo' },
  { value: ENSINO_SUPERIOR_INCOMPLETO, label: 'Superior Incompleto' },
  { value: ENSINO_SUPERIOR_COMPLETO, label: 'Superior Completo' },
  { value: MESTRADO, label: 'Mestrado' },
  { value: DOUTORADO, label: 'Doutorado' },
  { value: POS_DOUTORADO, label: 'Pós Doutorado' },
  { value: ESPECIALIZACAO_INCOMPLETO, label: 'Especialização Incompleto' },
  { value: ESPECIALIZACAO_COMPLETO, label: 'Especialização Completo' },
  { value: MESTRADO_INCOMPLETO, label: 'Mestrado Incompleto' },
  { value: DOUTORADO_INCOMPLETO, label: 'Doutorado Incompleto' },
  { value: POS_DOUTORADO_INCOMPLETO, label: 'Pós Doutorado Incompleto' },
  { value: ENSINO_TECNICO_INCOMPLETO, label: 'Ensino Técnico Incompleto' },
  { value: ENSINO_TECNICO_COMPLETO, label: 'Ensino Técnico Completo' },
]

/**
 * ENUM | TIPO DE DEFICIENCIA
 */
export const TIPO_DEFICIENCIA_NENHUMA = 0
export const TIPO_DEFICIENCIA_INTELECTUAL = 1
export const TIPO_DEFICIENCIA_AUDITIVA = 2
export const TIPO_DEFICIENCIA_VISUAL = 3
export const TIPO_DEFICIENCIA_MOTORA = 4

export const enumTipoDeficiencia = [
  { value: TIPO_DEFICIENCIA_NENHUMA, label: 'Nenhuma' },
  { value: TIPO_DEFICIENCIA_INTELECTUAL, label: 'Intelectual' },
  { value: TIPO_DEFICIENCIA_AUDITIVA, label: 'Auditiva' },
  { value: TIPO_DEFICIENCIA_VISUAL, label: 'Visual' },
  { value: TIPO_DEFICIENCIA_MOTORA, label: 'Motora' },
]

/**
 * ENUM | OPÇÕES DE PARENTESCO RESPONSÁVEL
 */
export const CONJUGE = 1
export const FILHO = 2
export const ENTEADO = 3
export const PAI = 4
export const MAE = 5
export const AVOS = 6
export const IRMAO = 7
export const PESSOA_SOB_GUARDA = 8
export const PADASTRO = 9
export const MADASTRA = 10
export const TUTOR = 11
export const VIUVO = 12
export const OUTROS = 13

export const enumParentescoResponsavel = [
  { value: CONJUGE, label: 'Cônjuge ou União Estável' },
  { value: FILHO, label: 'Filho(a)' },
  { value: ENTEADO, label: 'Enteado(a)' },
  { value: PAI, label: 'Pai' },
  { value: MAE, label: 'Mãe' },
  { value: AVOS, label: 'Avós' },
  { value: IRMAO, label: 'Irmão(ã)' },
  { value: PESSOA_SOB_GUARDA, label: 'Pessoa sob guarda' },
  { value: PADASTRO, label: 'Padrasto' },
  { value: MADASTRA, label: 'Madrasta' },
  { value: TUTOR, label: 'Tutor' },
  { value: VIUVO, label: 'Viúvo(a)' },
  { value: OUTROS, label: 'Outros' },
];

/**
 * ENUM | PROGRAMA BOLSA ESTUDO
 */
export const PROBEM = 1

export const enumProgramaBolsaEstudo = [
  { value: PROBEM, label: 'Programa Universitário do Bem (ProBem)' }
]
