import styled from 'styled-components'

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0 3rem;

  p {
    line-height: 2rem;
    text-align: center;

    strong {
      font-size: 1.1.rem;
      font-weight: 600;
    }

    span {
      font-weight: 500;
    }
  }

  div.actions {
    width: 100%;
    margin-top: 2rem;
    text-align: center;

    div {
      margin-top: .6rem;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--primary);
        border: 0;
        border-radius: 3rem;
        color: #fff;
        font-size: 1rem;
        padding: .5rem 1.4rem;

        transition: filter .2s;
        &:hover {
          filter: brightness(.8);
        }

        svg {
          margin-right: .4rem;
        }
      }

      @media (max-width: 520px) {
        flex-direction: column-reverse;

        button {
          width: 100%;
        }

        button:first-child {
          margin-top: 1rem;
        }
      }
    }
  }

  .MuiSvgIcon-fontSizeLarge {
    font-size: 5rem;
    color: #55c539;
    margin-bottom: 1.5rem;
  }

select {
    width: 500px;
    height: 40px;
    font-size: 20px;
  }

`
