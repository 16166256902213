import { ReactNode } from 'react'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'

interface ModalDefaultProps extends DialogProps {
  children: ReactNode;
  open: boolean;
  handleClose: () => void;
}

export function ModalDefault({ children, open, handleClose, ...rest }: ModalDefaultProps) {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      {...rest}
    >
      {children}
    </Dialog>
  )
}
