import styled from 'styled-components'
import StepWizard from 'react-step-wizard'

export const Container = styled.div`
  margin-top: 2rem;
`

export const ContainerStepWizard = styled(StepWizard)`
  margin-top: 2.5rem;
`

export const Course = styled.p`
  display: inline-block;
  background: var(--primary);
  margin-bottom: 1rem;
  padding: .6rem 2rem;
  color: #fff;
  font-size: 1rem;

  strong {
    font-size: 1.2rem;
    margin-left: .4rem;
  }

  @media (max-width: 560px) {
    margin-bottom: 1.2rem;
    padding: .4rem 1.4rem;
    font-size: 1rem;
  }
`
