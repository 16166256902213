import styled from 'styled-components'
import Paper from '@material-ui/core/Paper'
import Tab from '@material-ui/core/Tab'

export const Container = styled(Paper)`
  margin-top: 3rem;
  width: 100%;
`

export const WrapperTab = styled(Tab)`
  .MuiTab-wrapper {
    flex-direction: row;
    font-family: 'Poppins', sans-serif;
    text-transform: none;
    font-size: 1rem;
  }

  .MuiSvgIcon-root,
  .MuiTab-wrapper > *:first-child {
    margin-bottom: unset;
    margin-right: 1rem;
  }

  &.MuiTab-textColorPrimary.Mui-selected {
    color: var(--primary);
  }

  .MuiBadge-root {
    margin-right: 0 !important;
  }
`
