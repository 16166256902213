import { ChangeEvent } from 'react'
import Tabs from '@material-ui/core/Tabs'
import AssignmentIcon from '@material-ui/icons/Assignment'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import Badge from '@material-ui/core/Badge'
import { Container, WrapperTab } from './styles'
import { useSubscription } from '../../hooks/subscription'

interface HomeTabsProps {
  currentTab: number;
  handleTabs: (selectedTab: number) => void;
}

const IconWithBadge = () => {
  const { hasMessage } = useSubscription()

  return (
    <Badge
      variant="dot"
      color="error"
      invisible={!hasMessage}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <AssignmentIndIcon />
    </Badge>
  )
}

export function HomeTabs({ currentTab, handleTabs }: HomeTabsProps) {
  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    handleTabs(newValue)
  }

  return (
    <Container variant="outlined">
      <Tabs
        value={currentTab}
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
        aria-label="home tabs"
      >
        <WrapperTab icon={<AssignmentIcon />} label="Processos Seletivos" />
        <WrapperTab icon={<IconWithBadge />} label="Minhas Inscrições" />
      </Tabs>
    </Container>
  )
}
