import styled from 'styled-components'
import MuiDialogTitle from '@material-ui/core/DialogTitle'

export const ModalTitle = styled(MuiDialogTitle)`
  & {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .MuiTypography-h6 {
    font-family: 'Poppins', sans-serif;
  }
`
