import * as yup from 'yup'

export const schemaValidation = (disabled: boolean) => yup.object().shape({
  tipoMoradia: yup.number()
    .when([], {
      is: () => disabled,
      then: yup.number().notRequired(),
      otherwise: yup.number()
        .positive('Selecione o tipo de moradia')
        .required('Selecione o tipo de moradia'),
    }),
  qtdPessoasResidem: yup.number()
    .when([], {
      is: () => disabled,
      then: yup.number().notRequired(),
      otherwise: yup.number()
        .typeError('Número inválido')
        .integer('Número inválido')
        .positive('Número inválido')
        .required('Informe a quantidade de pessoas'),
    }),
  qtdPossuemRenda: yup.number()
    .when([], {
      is: () => disabled,
      then: yup.number().notRequired(),
      otherwise: yup.number()
        .typeError('Número inválido')
        .integer('Número inválido')
        .positive('Número inválido')
        .required('Informe a quantidade de pessoas que compôe a renda'),
    }),
})
