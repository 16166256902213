import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { StepperProgress } from '../../components/stepper-progress'
import { useSubscription } from '../../hooks/subscription'
import { subscriptionService } from '../../services/subscription'
import { isSitucaoEdit } from '../../config/enums/inscricao'
import {
  Family,
  Candidate,
  Responsible,
  Contact,
  Documents
} from '../../components/subscription-forms'

import { Container, ContainerStepWizard, Course } from './styles'

type TChangeStepWizard = {
  previousStep: number;
  activeStep: number;
}

type TRouteParams = {
  id?: number;
}

const STEPS = ['Renda Familiar', 'Dados do Candidato', 'Responsável', 'Contato', 'Documentos']

export function Subscription() {
  const history = useHistory()
  const routeParams = useRouteMatch()
  const { id: idSubscription } = routeParams.params as TRouteParams

  const [instanceStepWizard, setInstanceStepWizard] = useState<any>(null)
  const [activeStep, setActiveStep] = useState(1)
  const {
    selectedCourse,
    updateSubscriptionState,
    resetSubscriptionState,
    selectedSubscription,
    reloadLists
  } = useSubscription()

  const loadSubscription = async (id: number) => {
    const { succeeded, data } = await subscriptionService.get(id)
    if (succeeded && data) {
      if (!isSitucaoEdit(data.situacao)) {
        toast.warn('Essa inscrição não pode mais ser editada.')
        return history.push('/minhas-inscricoes')
      }
      setActiveStep(2)
      updateSubscriptionState(data)
    }
  }

  useEffect(() => {
    if (!selectedCourse.id && !idSubscription) {
      history.replace('/')
      toast.warn('Selecione um curso ou uma inscrição.')
    }

    if (idSubscription) {
      loadSubscription(idSubscription)
    }

    return () => {
      setActiveStep(1)
      resetSubscriptionState()
      reloadLists()
    }
  }, [])

  const handleChangeStepWizard = ({ activeStep }: TChangeStepWizard) => {
    setActiveStep(activeStep)
  }

  const handleStepperClick = () => {
    if (instanceStepWizard?.goToStep) {
      return instanceStepWizard.goToStep
    }
    return null
  }

  if (!selectedCourse.id && !selectedSubscription.id) {
    return <span />
  }

  return (
    <Container>
      <Course>
        {
          selectedCourse.nome ?
            <>Curso: <strong>{selectedCourse.nome}</strong></>
            : <>Curso: <strong>{selectedSubscription.turma?.nome}</strong></>
        }
      </Course>

      <StepperProgress
        activeStep={activeStep}
        steps={STEPS}
        handleStepClick={handleStepperClick()}
      />

      <ContainerStepWizard
        onStepChange={handleChangeStepWizard}
        instance={instance => setInstanceStepWizard(instance)}
        initialStep={activeStep}
      >
        <Family />
        <Candidate />
        <Responsible />
        <Contact />
        <Documents />
      </ContainerStepWizard>
    </Container>
  )
}
