export const env = {
  auth: {
    baseUrl: 'https://auth.sescgo.com.br/',
    endPointLogin: 'connect/authorize',
    endPointLogout: 'account/logout',
    clientId: 'psg',
    responseType: 'code',
    scope: 'offline_access',
    homeUrl: 'https://inscrevapsg.go.senac.br',
    logoutUrl: 'https://inscrevapsg.go.senac.br',
    routerNotAuth: ['/auth', '/auth/refresh-token'],
    redirectUri: 'https://inscrevapsg.go.senac.br/auth'
  },
  psg: {
    apiUrl: 'https://psgapi.go.senac.br/api/v1',
    parameters: {
      inscricoesLiberadas: true,
      salarioMinimo: 1100,
      limiteSalarioMinimoPerCapita: 2
    }
  }
}
