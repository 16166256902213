import styled from 'styled-components'

export const Container = styled.header`
  width: 100%;
  height: 6rem;
  box-shadow: 0px 1px 0px rgb(0 0 0 / 8%);
`

export const Content = styled.div`
  width: 100%;
  max-width: var(--max-width);
  height: inherit;
  margin: 0 auto;
  padding: 0 1rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    height: 5rem;
    width: auto;
  }

  > div.menu {
    display: flex;
    align-items: center;

    a {
      text-decoration: none;
      font-size: .9rem;
      padding: 0.5rem 1.2rem;
      font-weight: 400;
      color: #2e2f2f;
      border-radius: .2rem;
      transition: background .3s;

      &:hover {
      background: #dfecf7;
      }
    }

    @media (max-width: 600px) {
      display: none;
    }
  }

  > div.menu-mobile {
    display: none;
    align-items: center;

    @media (max-width: 600px) {
      display: flex;
    }
  }
`
