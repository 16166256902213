import styled from 'styled-components'

export const Container = styled.div`
  .MuiFormLabel-root {
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-weight: 400;
  }

  .MuiInputLabel-shrink {
    transform: translate(0, 1.5px) scale(1);
  }
`
