import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import AttachmentIcon from '@material-ui/icons/Attachment'
import Checkbox from '@material-ui/core/Checkbox'
import { useSubscription } from '../../../hooks/subscription'
import { subscriptionService } from '../../../services/subscription'
import { templatesDocumentService } from '../../../services/templates-document'
import { ModalTerms } from '../../modal/terms'
import { CardInfo } from './card-info'
import { CardInfoAtention } from './card-info-atention'
import { isSitucaoEdit } from '../../../config/enums/inscricao'
import {
  enumDocumentoAnexo,
  enumDocumentoAnexoMenorIdade,
  enumEscolaridade,
  DOCUMENTO_SITUACAO_ENVIANDO,
  canEditDoc
} from '../../../config/enums'

import { Container as ContainerForm } from '../default-styles'
import { Container, InputFile, Document, CheckCient, ButtonFinish } from './styles'
import { ModalFinishedSubscription } from '../../modal/finished-subscription'

export type Documento = {
  tipo: number;
  nome?: string;
  label: string;
  obrigatorio: boolean;
  file?: File;
  fileName?: string;
  situacao: number;
}

type ModalTermsState = {
  open: boolean;
  title: string;
  content: string;
}

type TermsState = {
  ciente: boolean;
  termoCompromisso: boolean;
  autodeclaracaoRenda: boolean;
  autodeclaracaoEscolaridade: boolean;
}

export function Documents(props: any) {
  const { isActive } = props // Props injetadas pelo StepWizard

  const { selectedSubscription } = useSubscription()
  const [editing, setEditing] = useState<boolean>(false)
  const [escolaridade, setEscolaridade] = useState<string>('')
  const [documents, setDocuments] = useState<Documento[]>([])
  const [modalFinishedIsOpen, setModalFinishedIsOpen] = useState<boolean>(false)
  const [termsState, setTermsState] = useState<TermsState>({
    ciente: false,
    termoCompromisso: false,
    autodeclaracaoRenda: false,
    autodeclaracaoEscolaridade: false
  })
  const [modalTermsState, setModalTermsState] = useState<ModalTermsState>({
    open: false,
    title: '',
    content: ''
  })

  const initFormValues = async () => {
    const initDocuments = [] as Documento[]
    const { candidato } = selectedSubscription

    if (candidato?.documentos?.length) {
      const enumDocumento = Number(candidato?.idade) < 18
        ? enumDocumentoAnexoMenorIdade : enumDocumentoAnexo

      candidato.documentos.map(d => {
        initDocuments.push({
          tipo: d.tipo,
          label: enumDocumento.find(e => e.value === d.tipo)?.label || '',
          obrigatorio: true,
          fileName: d.nome,
          situacao: d.situacao
        })
      })
      setTermsState({
        ciente: true,
        autodeclaracaoEscolaridade: true,
        autodeclaracaoRenda: true,
        termoCompromisso: true
      })
      setEditing(true)
    } else {
      const enumDocumento = Number(candidato?.idade) < 18
        ? enumDocumentoAnexoMenorIdade : enumDocumentoAnexo

      enumDocumento.map(d => {
        initDocuments.push({
          tipo: d.value,
          label: d.label,
          obrigatorio: d.required,
          situacao: DOCUMENTO_SITUACAO_ENVIANDO
        })
      })
    }
    setDocuments(initDocuments)
  }

  useEffect(() => {
    if (isActive) {
      const escolaridadeCandidadoId = selectedSubscription.candidato.escolaridade
      const descEscolaridade = enumEscolaridade
        .find(e => e.value === escolaridadeCandidadoId)?.label
      setEscolaridade(String(descEscolaridade))

      initFormValues()
      window.scrollTo(0, 0)
    }
  }, [isActive])

  const disableInput = (document: Documento) => {
    const { situacao } = selectedSubscription
    if (situacao) {
      const isEdit = isSitucaoEdit(situacao)
      if (!isEdit) {
        return true
      }
      return !canEditDoc(document.situacao)
    }
    return false
  }

  const handleCheckTerms = (event: any) => {
    const { name, checked } = event.target
    if (name) {
      setTermsState(prev => ({ ...prev, [name]: checked }))
    }
  }

  const handleClickTermoCompromisso = async () => {
    if (!selectedSubscription.id) {
      toast.warn('Nenhuma inscrição selecionada.')
      return
    }
    const { succeeded, data } = await templatesDocumentService.get('termo-compromisso', selectedSubscription.id)
    if (succeeded && data) {
      setModalTermsState({
        open: true,
        title: 'Termo de Compromisso',
        content: data.html
      })
    }
  }

  const handleClickAutoDeclaracao = async () => {
    if (!selectedSubscription.id) {
      toast.warn('Nenhuma inscrição selecionada.')
      return
    }
    const { succeeded, data } = await templatesDocumentService.get('auto-declaracao', selectedSubscription.id)
    if (succeeded && data) {
      setModalTermsState({
        open: true,
        title: 'Autodeclaração',
        content: data.html
      })
    }
  }

  const fileIsOk = (file: File): boolean => {
    const allowedTypes = ['application/pdf', 'image/png', 'image/jpg', 'image/jpeg']
    if (allowedTypes.every(type => file.type !== type)) {
      toast.warn('São permitidos apenas os formatos JPG, JPEG, PNG ou PDF')
      return false
    }

    const maxSize = 5000000;
    if (file.size > maxSize) {
      toast.warn('O tamanho máximo do arquivo deve ser até 5MB')
      return false
    }
    return true
  }

  const handleChangeFile = (files: any, type: number) => {
    if (!files || !files[0]) return;
    if (!fileIsOk(files[0])) return;

    const updatedDocuments = documents.map(d => {
      if (d.tipo === type) {
        return {
          ...d,
          file: files[0],
          fileName: files[0].name
        }
      }
      return d
    })
    setDocuments(updatedDocuments)
  }

  const documentsIsOk = (): boolean => {
    const incomplete = documents.filter(d => {
      if (d.obrigatorio && !d.file) {
        return true
      }
      return false
    })

    if (incomplete.length > 0) {
      if (!editing) {
        toast.warn('Selecione todos os documentos solicitados')
        return false
      }
    }

    if (!termsState.ciente
      || !termsState.autodeclaracaoRenda
      || !termsState.autodeclaracaoEscolaridade
      || !termsState.termoCompromisso) {
      toast.warn('É necessário aceitar os termos para finalizar')
      return false
    }

    return true
  }

  const onSubmit = async () => {
    if (!documentsIsOk()) return;

    const formData = new FormData()

    documents.forEach(d => {
      if (!!d.file) {
        formData.append(
          'documentos',
          d.file,
          d.fileName + '|' + d.tipo
        )
      }
    })

    if (!formData.has('documentos')) {
      setModalFinishedIsOpen(true)
      return
    }

    if (!selectedSubscription.id) {
      return
    }

    const { succeeded, message, data } = await subscriptionService
      .documents(formData, selectedSubscription.id)

    if (!succeeded || !data) {
      toast.error(message)
      return
    }

    setModalFinishedIsOpen(true)
  }

  return (
    <Container>
      <h3>Documentos Obrigatórios</h3>
      <CardInfo />
      <ContainerForm>
        {
          documents.map((doc, index) => (
            <Document key={index}>
              <InputFile disabled={disableInput(doc)}>
                <input
                  disabled={disableInput(doc)}
                  name={`input-file-${doc.tipo}`}
                  type="file"
                  onChange={e => handleChangeFile(e.target.files, doc.tipo)}
                />
                <CloudUploadIcon /> Selecionar Arquivo
              </InputFile>
              <div>
                <span>{doc.label}</span>
                <strong>
                  {
                    doc.fileName ? <><AttachmentIcon /> {doc.fileName}</>
                      : ''
                  }
                </strong>
              </div>
            </Document>
          ))
        }
        <CardInfoAtention />
        <CheckCient>
          <Checkbox
            id="ciente"
            name="ciente"
            onChange={handleCheckTerms}
            value={termsState.ciente}
            checked={termsState.ciente}
            color="primary"
            inputProps={{ 'aria-label': 'ciente' }}
          />
          <label htmlFor="ciente">
            Estou ciente que todas as informações aqui prestadas são verdadeiras e que para conclusão da inscrição/matricula devo proceder o aceite da autodeclaracao de renda, da autodeclaraçao de escolaridade, do termo de compromisso e da autodeclaraçao de trabalho/experiência profissional.
          </label>
        </CheckCient>
        <CheckCient>
          <Checkbox
            id="autodeclaracaoRenda"
            name="autodeclaracaoRenda"
            onChange={handleCheckTerms}
            value={termsState.autodeclaracaoRenda}
            checked={termsState.autodeclaracaoRenda}
            color="primary"
            inputProps={{ 'aria-label': 'autodeclaração de renda' }}
          />
          <label htmlFor="autodeclaracaoRenda">
            Declaro como verdade a
            <button
              className="link"
              type="button"
              onClick={handleClickAutoDeclaracao}
            > Autodeclaração de Renda</button>
          </label>
        </CheckCient>
        <CheckCient>
          <Checkbox
            id="autodeclaracaoEscolaridade"
            name="autodeclaracaoEscolaridade"
            onChange={handleCheckTerms}
            value={termsState.autodeclaracaoEscolaridade}
            checked={termsState.autodeclaracaoEscolaridade}
            color="primary"
            inputProps={{ 'aria-label': 'autodeclaração de escolaridade' }}
          />
          <label htmlFor="autodeclaracaoEscolaridade">
            Declaro como verdade a escolaridade do candidato {escolaridade}
          </label>
        </CheckCient>
        <CheckCient>
          <Checkbox
            id="termoCompromisso"
            name="termoCompromisso"
            onChange={handleCheckTerms}
            value={termsState.termoCompromisso}
            checked={termsState.termoCompromisso}
            color="primary"
            inputProps={{ 'aria-label': 'termo de compromisso' }}
          />
          <label htmlFor="termoCompromisso">
            Concordo com o
            <button
              className="link"
              onClick={handleClickTermoCompromisso}
            >Termo de Compromisso.</button>
          </label>
        </CheckCient>

        <ButtonFinish>
          <button
            type="button"
            onClick={onSubmit}
          >
            Finalizar
          </button>
        </ButtonFinish>
      </ContainerForm>

      <ModalTerms
        open={modalTermsState.open}
        title={modalTermsState.title}
        content={modalTermsState.content}
        canPrint={true}
        handleClose={() => { setModalTermsState({ open: false, title: '', content: '' }) }}
      />
      <ModalFinishedSubscription
        open={modalFinishedIsOpen}
        setIsOpen={setModalFinishedIsOpen}
      />
    </Container>
  )
}
