import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { env } from '../../config/env'
import { authService } from '../../services/auth'

export function Login() {
  const history = useHistory()
  const paramsBusca = new URLSearchParams(history.location.search)

  const code = paramsBusca.get('code')

  const loading = async () => {
    if (code !== null) {
      await authService.loginByAuthorizationCode({
        code,
        callback: env.auth.redirectUri
      })
      return
    }

    authService.redirectToLogin()
  }

  useEffect(() => {
    loading()
  }, [])

  return (
    <></>
  )
}
