import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { InputText, InputSelect, InputCpf, InputDate } from '../../inputs'
import { schemarResponsibleValidation } from './schema-validation'
import { useSubscription } from '../../../hooks/subscription'
import { helper } from '../../../helpers'
import { subscriptionService } from '../../../services/subscription'
import {
  FormDataResponsible,
  ResponsavelViewModel,
  InscricaoViewModel
} from '../../../_types'
import {
  enumGenero,
  enumEstadoCivil,
  enumTipoDocumento,
  enumParentescoResponsavel,
  enumSimNao,
  enumTipoDeficiencia
} from '../../../config/enums'

import { ButtonNext, Container, Grid } from '../default-styles'

export function Responsible(props: any) {
  const { isActive, nextStep, goToStep } = props // Props injetadas pelo StepWizard

  const { selectedSubscription, updateSubscriptionState } = useSubscription()
  const [editing, setEditing] = useState<boolean>(false)

  const { register, handleSubmit, errors, reset, watch, setError, clearErrors } = useForm<FormDataResponsible>({
    resolver: yupResolver(schemarResponsibleValidation(editing))
  })

  const showFieldOutroEstadoCivil = Number(watch('estadoCivil')) === 0
  const showFieldProfissao = watch('trabalha') === 'S'

  const initFormValues = () => {
    const { responsavel } = selectedSubscription.candidato
    if (Number(selectedSubscription.candidato?.idade) >= 18) {
      goToStep(4)
      return
    }
    if (responsavel?.id) {
      setEditing(true)
      reset({
        ...responsavel,
        dataNascimento: new Date(responsavel.dataNascimento)
          .toLocaleString('pt-BR', { dateStyle: 'short' }),
        trabalha: !!responsavel.profissao ? 'S' : 'N'
      })
    }
  }

  useEffect(() => {
    if (isActive) {
      initFormValues()
      window.scrollTo(0, 0)
    }
  }, [isActive])

  const checkCpf = async (cpfForm: string) => {
    clearErrors('cpf')
    if (!cpfForm) return;

    const cpf = helper.getCpfClean(cpfForm)
    if (cpf.length !== 11) {
      setError('cpf', {
        type: 'manual',
        message: 'CPF inválido.'
      })
      return
    }
    const cpfCandidato = selectedSubscription.candidato?.cpf
    if (helper.getCpfClean(cpfForm) === cpfCandidato) {
      setError('cpf', {
        type: 'manual',
        message: 'O CPF do responsável não pode ser o mesmo do candidato'
      })
      return
    }
  }

  const checkDataNascimento = (dataNascimentoForm: string): boolean => {
    if (editing) return true;

    const dataNascimento = helper.getDate(dataNascimentoForm)
    const idadeResponsavel = helper.getIdade(dataNascimento)
    const idadeRequerida = 18 /* Maior de idade */

    if (idadeResponsavel < idadeRequerida) {
      toast.error(`O responsável pelo candidato deve ser maior de idade`)
      return false
    }
    return true
  }

  const responsibleIsOk = (data: FormDataResponsible): boolean => {
    if (!checkDataNascimento(data.dataNascimento))
      return false

    return true
  }

  const checkNeedToUpdate = (dataForm: any): boolean => {
    if (!editing) return true;

    const subscriptionResponsibleCopy: any = selectedSubscription.candidato.responsavel
    for (const field in dataForm) {
      if (String(dataForm[field]) !== String(subscriptionResponsibleCopy[field])) {
        return true
      }
    }
    return false
  }

  const onSubmit = handleSubmit(async (dataForm) => {
    if (!responsibleIsOk(dataForm)) return;

    if (!checkNeedToUpdate(dataForm)) {
      return nextStep()
    }

    let responsavel = {
      ...dataForm
    } as ResponsavelViewModel

    if (!editing) {
      responsavel = {
        ...responsavel,
        cpf: helper.getCpfClean(dataForm.cpf),
        dataNascimento: helper.getDate(dataForm.dataNascimento).toISOString()
      }
    }

    const inscricao = {
      ...selectedSubscription,
      candidato: {
        ...selectedSubscription.candidato,
        responsavel: {
          ...selectedSubscription.candidato.responsavel,
          ...responsavel
        }
      }
    } as InscricaoViewModel

    const { succeeded, message, data } = await subscriptionService.save(inscricao)

    if (!succeeded || !data) {
      toast.error(message)
      return
    }

    updateSubscriptionState(data)
    nextStep()
  })

  return (
    <Container
      as="form"
      onSubmit={onSubmit}
    >
      <h3>Responsável</h3>
      <Grid
        marginTop
        marginBottom
      >
        <InputCpf
          name="cpf"
          label="CPF"
          inputRef={register}
          invalid={errors.cpf}
          handleCpf={checkCpf}
          disabled={editing}
        />
        <InputText
          name="nome"
          label="Nome Completo"
          inputRef={register}
          invalid={errors.nome}
          disabled={editing}
        />
      </Grid>
      <Grid marginBottom>
        <InputDate
          name="dataNascimento"
          label="Data de Nascimento"
          inputRef={register}
          invalid={errors.dataNascimento}
          disabled={editing}
        />
        <InputText
          name="nomeMae"
          label="Nome da Mãe"
          inputRef={register}
          invalid={errors.nomeMae}
          disabled={editing}
        />
      </Grid>
      <Grid marginBottom>
        <InputSelect
          name="estadoCivil"
          label="Estado Civil"
          inputRef={register}
          invalid={errors.estadoCivil}
          options={enumEstadoCivil}
        />
        {
          showFieldOutroEstadoCivil &&
          <InputText
            name="outroEstadoCivil"
            label="Especifique o Estado Civil"
            inputRef={register}
            invalid={errors.outroEstadoCivil}
            disabled={editing}
          />
        }
      </Grid>
      <Grid marginBottom>
        <InputSelect
          name="sexo"
          label="Gênero"
          inputRef={register}
          invalid={errors.sexo}
          options={enumGenero}
        />
        <InputSelect
          name="grauParentescoTitular"
          label="Parentesco com o Candidato"
          inputRef={register}
          invalid={errors.grauParentescoTitular}
          options={enumParentescoResponsavel}
        />
      </Grid>
      <Grid marginBottom>
        <InputSelect
          name="tipoDocumento"
          label="Tipo de Documento"
          inputRef={register}
          invalid={errors.tipoDocumento}
          options={enumTipoDocumento}
        />
        <InputText
          name="numeroDocumento"
          label="Nº do Documento"
          inputRef={register}
          invalid={errors.numeroDocumento}
        />
      </Grid>
      <Grid marginBottom>
        <InputText
          name="naturalidade"
          label="Naturalidade"
          inputRef={register}
          invalid={errors.naturalidade}
        />
        <InputText
          name="nacionalidade"
          label="Nacionalidade"
          inputRef={register}
          invalid={errors.nacionalidade}
        />
      </Grid>
      <Grid marginBottom>
        <InputSelect
          name="tipoDeficiencia"
          label="Possui alguma deficiência?"
          inputRef={register}
          invalid={errors.tipoDeficiencia}
          options={enumTipoDeficiencia}
        />
      </Grid>
      <Grid marginBottom>
        <InputSelect
          name="trabalha"
          label="Trabalha?"
          inputRef={register}
          invalid={errors.trabalha}
          options={enumSimNao}
          disabled={editing}
        />
        {
          showFieldProfissao &&
          <InputText
            name="profissao"
            label="Profissão"
            inputRef={register}
            invalid={errors.profissao}
            disabled={editing}
          />
        }
      </Grid>
      <Grid inline marginBottom>
        <InputSelect
          name="participouPSG"
          label="Já participou do PSG antes?"
          inputRef={register}
          invalid={errors.participouPSG}
          options={enumSimNao}
        />
      </Grid>
      <ButtonNext
        type="submit"
      >
        Avançar <ArrowForwardIcon />
      </ButtonNext>
    </Container>
  )
}
