import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import { Container } from './styles'

interface StepperProgressProps {
  steps: string[];
  activeStep: number;
  handleStepClick?: (step: number) => void;
}

export function StepperProgress({ steps, activeStep, handleStepClick }: StepperProgressProps) {
  const handleClick = (index: number) => {
    if (index < activeStep && handleStepClick) {
      handleStepClick(index + 1)
    }
  }

  return (
    <Container>
      <Stepper activeStep={activeStep - 1} alternativeLabel>
        {steps.map((label, index) => (
          <Step
            key={label}
            onClick={() => handleClick(index)}
            style={{ cursor: index < (activeStep - 1) ? 'pointer' : 'initial' }}
          >
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Container>
  );
}
