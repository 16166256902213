import { useState } from 'react'
import { useSubscription } from '../../hooks/subscription'
import { AtendimentosViewModel } from '../../_types'
import { ModalCancelSubscription } from '../modal/cancel-subscription'
import { ModalMessage } from '../modal/message'
import { CardSubscription } from './card-subscription'
import { Content } from './styles'

type ModalMessageState = {
  open: boolean;
  messages: AtendimentosViewModel[] | null;
}

type ModalCancelState = {
  open: boolean;
  idSubscription: number | null;
}

export function MySubscriptions() {
  const { listMySubscriptions } = useSubscription()

  const [modalCancelState, setModalCancelState] = useState<ModalCancelState>({
    open: false,
    idSubscription: null
  })
  const [modalMessageState, setModalMessageState] = useState<ModalMessageState>({
    open: false,
    messages: null
  })

  const handleOpenModalCancel = (idSubscription: number) => {
    setModalCancelState({
      open: true,
      idSubscription
    })
  }

  const handleCloseModalCancel = () => {
    setModalCancelState({
      open: false,
      idSubscription: null
    })
  }

  const handleOpenModalMessage = (messages: AtendimentosViewModel[]) => {
    setModalMessageState({
      open: true,
      messages
    })
  }

  const handleCloseModalMessage = () => {
    setModalMessageState({
      open: false,
      messages: null
    })
  }

  return (
    <Content>
      {
        listMySubscriptions.length === 0 ?
          <p>Não foram localizadas inscrições.</p>
          : listMySubscriptions
            .map((subscription, index) =>
              <CardSubscription
                key={index}
                subscription={subscription}
                handleModalMessage={handleOpenModalMessage}
                handleModalCancel={handleOpenModalCancel}
              />
            )
      }

      <ModalMessage
        open={modalMessageState.open}
        messages={modalMessageState.messages}
        handleClose={handleCloseModalMessage}
      />
      <ModalCancelSubscription
        open={modalCancelState.open}
        idSubscription={modalCancelState.idSubscription}
        handleClose={handleCloseModalCancel}
      />
    </Content>
  )
}
