import styled from 'styled-components'

export const Info = styled.div`
  > div {
    display: grid;

    border-left: .4rem solid #64b5f6;
    border-right: .4rem solid #64b5f6;
    border-top: .4rem solid #64b5f6;
    border-bottom: .4rem solid #64b5f6;
    background-color: #e3f2fd;
    padding: 1rem;
    margin-top: 1rem;
    margin-bottom: .5rem;

    ul {
      padding: .5rem 1rem;

      li:first-child {
        list-style: none;
        margin-bottom: .4rem;
      }

      li {
        font-size: .9rem;
      }
    }
  }

   h3 {
        text-align: center;
      }

  p {
    font-size: .9rem;
    color: #ef5350;
    padding: .2rem .6rem;

    &:last-child {
      margin-bottom: 1.6rem;
    }
  }
`
