import { ReactNode } from 'react'
import { AuthProvider } from './auth'
import { SubscriptionProvider } from './subscription'

interface AppProviderProps {
  children: ReactNode
}

export const AppProvider = ({ children }: AppProviderProps) => (
  <AuthProvider>
    <SubscriptionProvider>
      {children}
    </SubscriptionProvider>
  </AuthProvider>
)
