import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { InputText, InputSelect, InputCpf, InputDate } from '../../inputs'
import { schemaCandidateValidation } from './schema-validation'
import { useSubscription } from '../../../hooks/subscription'
import { candidateService } from '../../../services/cadidate'
import { courseService } from '../../../services/course'
import { subscriptionService } from '../../../services/subscription'
import { helper } from '../../../helpers'
import {
  FormDataCandidate,
  CandidatoViewModel,
  InscricaoViewModel,
  TurmaViewModel
} from '../../../_types'
import {
  enumGenero,
  enumEstadoCivil,
  enumTipoDocumento,
  enumEscolaridade,
  enumTipoDeficiencia,
  enumSimNao,
  enumTrueFalse,
  PROBEM
} from '../../../config/enums'

import { Container, Grid, ButtonNext } from '../default-styles'

export function Candidate(props: any) {
  const { isActive, nextStep } = props // Props injetadas pelo StepWizard

  const {
    selectedSubscription,
    updateSubscriptionState,
    selectedCourse,
  } = useSubscription()
  const [cpf, setCpf] = useState<string>('')
  const [editing, setEditing] = useState<boolean>(false)
  const [listTurmas, setListTurmas] = useState<TurmaViewModel[]>([])

  const { register, handleSubmit, errors, reset, watch, setError, clearErrors } = useForm<FormDataCandidate>({
    resolver: yupResolver(schemaCandidateValidation(editing))
  })

  const showFieldOutroEstadoCivil = Number(watch('estadoCivil')) === 0
  const showFieldProfissao = watch('trabalha') === 'S'

  const initFormValues = async () => {
    if (selectedSubscription.candidato) {
      setEditing(true)
      setListTurmas([selectedSubscription.turma])
      const { dataNascimento, profissao, programaBolsaEstudo } = selectedSubscription.candidato
      reset({
        ...selectedSubscription.candidato,
        dataNascimento: new Date(dataNascimento)
          .toLocaleString('pt-BR', { dateStyle: 'short' }),
        trabalha: !!profissao ? 'S' : 'N',
        turmaId: selectedSubscription.turmaId,
        bolsistaProBem: programaBolsaEstudo === PROBEM ? 'S' : 'N'
      })
    }

    if (selectedCourse.id) {
      const { succeeded, data, message } = await courseService.getTurmas(selectedCourse.id)
      if (!succeeded) {
        toast.error(message)
        return
      }
      if (data) {
        if (data.length <= 0) {
          toast.warn('Não há mais vagas disponíveis para este Curso')
          return
        }
        setListTurmas(data)
      }
    }
  }

  useEffect(() => {
    initFormValues()
  }, [selectedSubscription])

  useEffect(() => {
    if (isActive) {
      window.scrollTo(0, 0)
    }
  }, [isActive])

  const mountLabelCourse = (turma: TurmaViewModel): string => {
    const dataInicio = new Date(turma.dataInicio).toLocaleDateString('pt-BR')
    const dataFim = new Date(turma.dataFim).toLocaleDateString('pt-BR')
    const horario = `${turma.horaInicio.slice(0, 5)} às ${turma.horaFim.slice(0, 5)}`
    const modalidade = turma.tipoEnsino ? `| ${turma.tipoEnsino.toUpperCase()}` : ''

    return `Período: ${dataInicio} - ${dataFim} | Horário: ${horario} | ${turma.frequenciaSemanal} ${modalidade}`
  }

  const checkCpf = async (cpfForm: string) => {
    clearErrors('cpf')
    if (!cpfForm) return;

    const cpf = helper.getCpfClean(cpfForm)
    if (cpf.length !== 11) {
      setError('cpf', {
        type: 'manual',
        message: 'CPF inválido.'
      })
      return
    }

    const { succeeded, message } = await candidateService.checkCadidateSubscribe(cpf, selectedCourse.id)
    if (!succeeded) {
      toast.error(message)
      return
    }
    setCpf(cpf)
  }

  const checkDataNascimento = (dataNascimentoForm: string): boolean => {
    if (editing) return true;

    const dataNascimento = helper.getDate(dataNascimentoForm)
    const idadeCandidato = helper.getIdade(dataNascimento)
    const idadeRequerida = selectedCourse.idadeMinima

    if (idadeCandidato < idadeRequerida) {
      toast.warn(`A idade requerida para este curso é ${idadeRequerida} anos`)
      return false
    }
    return true
  }

  const checkEscolaridade = (escolaridadeForm: number): boolean => {
    const escolaridadeMinima = selectedCourse.escolaridadeMinima

    if (escolaridadeForm < escolaridadeMinima) {
      const descricaoEscolaridade = enumEscolaridade.find(e => e.value === escolaridadeMinima)?.label
      toast.warn(`A escolaridade requerida para este curso é ${descricaoEscolaridade}`)
      return false
    }
    return true
  }

  const candidateIsOk = (data: FormDataCandidate): boolean => {
    if (!checkDataNascimento(data.dataNascimento))
      return false

    if (!checkEscolaridade(data.escolaridade))
      return false

    return true
  }

  const getObjectSubscriptionUpdate = (dataForm: FormDataCandidate): InscricaoViewModel => {
    const candidato = {
      ...dataForm,
      outroEstadoCivil: dataForm.estadoCivil === 0 ? dataForm.outroEstadoCivil : ''
    } as CandidatoViewModel

    const inscricao = {
      ...selectedSubscription,
      candidato: {
        ...selectedSubscription.candidato,
        ...candidato,
        profissao: dataForm.trabalha === 'S' ? dataForm.profissao : '',
        programaBolsaEstudo: dataForm.bolsistaProBem === 'S' ? PROBEM : null
      }
    } as InscricaoViewModel

    return inscricao
  }

  const getObjectSubscriptionCreate = (dataForm: FormDataCandidate): InscricaoViewModel => {
    const candidato = {
      ...dataForm,
      cpf: helper.getCpfClean(cpf),
      dataNascimento: helper.getDate(dataForm.dataNascimento).toISOString(),
      programaBolsaEstudo: dataForm.bolsistaProBem === 'S' ? PROBEM : null
    }

    const unidadeId = selectedCourse
      .turmas.find(t => t.id === Number(dataForm.turmaId))?.unidadeId

    const inscricao = {
      ...selectedSubscription,
      candidato,
      cursoId: selectedCourse.id,
      turmaId: dataForm.turmaId,
      unidadeId
    } as InscricaoViewModel

    return inscricao
  }

  const checkNeedToUpdate = (dataForm: any): boolean => {
    if (!editing) return true;

    const subscriptionCandidateCopy: any = selectedSubscription.candidato
    for (const field in dataForm) {
      if (String(dataForm[field]) !== String(subscriptionCandidateCopy[field])) {
        return true
      }
    }
    return false
  }

  const onSubmit = handleSubmit(async (dataForm) => {
    if (!candidateIsOk(dataForm)) return;

    if (!checkNeedToUpdate(dataForm)) {
      return nextStep()
    }

    let inscricao = null
    if (editing) {
      inscricao = getObjectSubscriptionUpdate(dataForm)
    } else {
      inscricao = getObjectSubscriptionCreate(dataForm)
    }

    const { succeeded, message, data } = await subscriptionService.save(inscricao)
    if (!succeeded || !data) {
      toast.error(message)
      return
    }

    updateSubscriptionState(data)
    nextStep()
  })

  return (
    <Container
      as="form"
      onSubmit={onSubmit}
    >
      <h3>Dados do Candidato</h3>
      <Grid
        inline
        marginTop
        marginBottom
      >
        <h4>{`Curso: ${selectedCourse.nome || selectedSubscription.turma.nome}`}</h4>
        {
          !!listTurmas.length &&
          <InputSelect
            name="turmaId"
            label="Escolha a turma desejada"
            inputRef={register}
            invalid={errors.turmaId}
            disabled={editing}
            options={listTurmas
              .map(t => ({
                value: t.id,
                label: mountLabelCourse(t)
              }))
            }
          />
        }
      </Grid>
      <Grid marginBottom>
        <InputCpf
          name="cpf"
          label="CPF"
          inputRef={register}
          invalid={errors.cpf}
          handleCpf={checkCpf}
          disabled={editing}
        />
        <InputText
          name="nome"
          label="Nome Completo"
          inputRef={register}
          invalid={errors.nome}
          disabled={editing}
        />
        <InputDate
          name="dataNascimento"
          label="Data de Nascimento"
          inputRef={register}
          invalid={errors.dataNascimento}
          disabled={editing}
        />
        <InputText
          name="nomeMae"
          label="Nome da Mãe"
          inputRef={register}
          invalid={errors.nomeMae}
          disabled={editing}
        />
      </Grid>
      <Grid marginBottom>
        <InputSelect
          name="estadoCivil"
          label="Estado Civil"
          inputRef={register}
          invalid={errors.estadoCivil}
          options={enumEstadoCivil}
        />
        {
          showFieldOutroEstadoCivil &&
          <InputText
            name="outroEstadoCivil"
            label="Especifique o Estado Civil"
            inputRef={register}
            invalid={errors.outroEstadoCivil}
          />
        }
      </Grid>
      <Grid marginBottom>
        <InputSelect
          name="tipoDocumento"
          label="Tipo de Documento"
          inputRef={register}
          invalid={errors.tipoDocumento}
          options={enumTipoDocumento}
          disabled={editing}
        />
        <InputText
          name="numeroDocumento"
          label="Nº do Documento"
          inputRef={register}
          invalid={errors.numeroDocumento}
          disabled={editing}
        />
        <InputText
          name="naturalidade"
          label="Naturalidade"
          inputRef={register}
          invalid={errors.naturalidade}
          disabled={editing}
        />
        <InputText
          name="nacionalidade"
          label="Nacionalidade"
          inputRef={register}
          invalid={errors.nacionalidade}
          disabled={editing}
        />
      </Grid>
      <Grid marginBottom>
        <InputSelect
          name="sexo"
          label="Gênero"
          inputRef={register}
          invalid={errors.sexo}
          options={enumGenero}
          disabled={editing}
        />
        <InputSelect
          name="tipoDeficiencia"
          label="Possui alguma deficiência?"
          inputRef={register}
          invalid={errors.tipoDeficiencia}
          options={enumTipoDeficiencia}
        />
      </Grid>
      <Grid marginBottom>
        <InputSelect
          name="trabalha"
          label="Trabalha?"
          inputRef={register}
          invalid={errors.trabalha}
          options={enumSimNao}
          disabled={editing}
        />
        {
          showFieldProfissao &&
          <InputText
            name="profissao"
            label="Profissão"
            inputRef={register}
            invalid={errors.profissao}
            disabled={editing}
          />
        }
      </Grid>
      <Grid inline marginBottom>
        <InputSelect
          name="escolaridade"
          label="Escolaridade"
          inputRef={register}
          invalid={errors.escolaridade}
          options={enumEscolaridade}
        />
      </Grid>
      <Grid inline marginBottom>
        <InputSelect
          name="estudanteRedePublica"
          label="Estudante/Egresso da Rede Pública?"
          inputRef={register}
          invalid={errors.estudanteRedePublica}
          options={enumTrueFalse}
        />
      </Grid>
      <Grid inline marginBottom>
        <InputSelect
          name="participouPSG"
          label="Já participou do PSG antes?"
          inputRef={register}
          invalid={errors.participouPSG}
          options={enumSimNao}
        />
      </Grid>
      <Grid inline marginBottom>
        <InputSelect
          name="bolsistaProBem"
          label="Participa de algum programa/projeto da OVG"
          inputRef={register}
          invalid={errors.bolsistaProBem}
          options={enumSimNao}
        />
      </Grid>
      <ButtonNext
        type="submit"
      >
        Avançar <ArrowForwardIcon />
      </ButtonNext>
    </Container>
  )
}
