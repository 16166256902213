import { psgApi, TResponse } from './http'

export const candidateService = {
  checkCadidateSubscribe: async (cpf: string, cursoId: number): Promise<TResponse<any>> => {
    try {
      const response = await psgApi
        .get(`inscricao/validar-candidato-ja-inscrito/${cpf}/${cursoId}`,
          { activeSpinner: true })

      if (response && response.status === 400) {
        throw new Error('Este candidato já está inscrito em outra Turma deste Curso, não será possível continuar com a inscrição')
      }

      if (!response || response.status > 299) {
        throw new Error('Tivemos um problema para verificar o Cpf')
      }

      return {
        succeeded: true,
        data: response.data,
        message: null
      }
    } catch (error) {
      return {
        succeeded: false,
        data: null,
        message: error.message
      }
    }
  }
}
