export * from './composicao-renda'
export * from './dados-pessoais'
export * from './documentos'

/**
 * ENUM | OPÇÕES DE SIM / NAO
 */
export const SIM = 'S'
export const NAO = 'N'

export const enumSimNao = [
  { value: SIM, label: 'Sim' },
  { value: NAO, label: 'Não' }
]

/**
 * ENUM | OPÇÕES DE VERDADEIRO / FALSO
 */
export const TRUE = 'true'
export const FALSE = 'false'

export const enumTrueFalse = [
  { value: TRUE, label: 'Sim' },
  { value: FALSE, label: 'Não' }
]
