import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { CursoViewModel, InscricaoViewModel } from '../_types'
import { useAuth } from './auth'
import { subscriptionService } from '../services/subscription'
import { courseService } from '../services/course'
import { SITUACAO_AGUARDANDO_RETORNO_CLIENTE } from '../config/enums/inscricao'
import { ModalFilterCity } from '../components/modal/filter-city'

interface SubscriptionProviderProps {
  children: ReactNode;
}

interface SubscriptionContextData {
  listCourses: CursoViewModel[];
  listMySubscriptions: InscricaoViewModel[];
  selectedCourse: CursoViewModel;
  selectedSubscription: InscricaoViewModel;
  setSelectedCourse: (course: CursoViewModel) => void;
  resetSubscriptionState: () => void;
  updateSubscriptionState: (subscription: InscricaoViewModel) => void;
  reloadLists: () => void;
  hasMessage: boolean;
}

const SubscriptionContex = createContext({} as SubscriptionContextData)

const SubscriptionProvider = ({ children }: SubscriptionProviderProps) => {
  const { logged } = useAuth()
  const [hasMessage, setHasMessage] = useState<boolean>(false)
  const [listCourses, setListCourses] = useState<CursoViewModel[]>([])
  const [listMySubscriptions, setListMySubscriptions] = useState<InscricaoViewModel[]>([])
  const [selectedCourse, setSelectedCourse] = useState<CursoViewModel>({} as CursoViewModel)
  const [selectedSubscription, setSelectedSubscription] = useState<InscricaoViewModel>({} as InscricaoViewModel)

  const loadListCourses = async () => {
    const { succeeded, data } = await courseService.getAll()
    if (succeeded && data) {
      setListCourses(data)
    }
  }

  const loadMySubscriptions = async () => {
    const { succeeded, data } = await subscriptionService.getAll()
    if (succeeded && data) {
      setListMySubscriptions(data)
    }
  }

  const updateSubscriptionState = (subscription: InscricaoViewModel): void => {
    setSelectedSubscription(subscription)
  }

  const resetSubscriptionState = () => {
    setSelectedSubscription({} as InscricaoViewModel)
    setSelectedCourse({} as CursoViewModel)
  }

  const reloadLists = () => {
    loadListCourses()
    loadMySubscriptions()
  }

  const checkHasMessage = () => {
    const subscriptionAwaitingAnswer = listMySubscriptions
      .filter(s => s.situacao === SITUACAO_AGUARDANDO_RETORNO_CLIENTE)

    setHasMessage(!!subscriptionAwaitingAnswer.length)
  }
  // aqui
  const [unidadeId, setUnidadeId] = useState("")
  const [modalFilterCityIsOpen, setModalFilterCityIsOpen] = useState(true)
  const handleCloseModalFilterCity = () => {
    setModalFilterCityIsOpen(false)
  }
  const loadListCoursesById = async (unidadeId: string) => {
    const { succeeded, data } = await courseService.getUnidadeById(unidadeId)
    if (succeeded && data) {
      setListCourses(data)
    }
  }

  useEffect(() => {
    console.log("unidadeId: " + unidadeId);
    loadListCoursesById(unidadeId)
  }, [unidadeId]);

  useEffect(() => {
    checkHasMessage()
  }, [listMySubscriptions])

  useEffect(() => {
    if (logged) {
      loadListCourses()
      loadMySubscriptions()
    }
  }, [logged])

  return (
    <SubscriptionContex.Provider
      value={{
        listCourses,
        listMySubscriptions,
        selectedCourse,
        selectedSubscription,
        setSelectedCourse,
        resetSubscriptionState,
        updateSubscriptionState,
        reloadLists,
        hasMessage
      }}
    >
      <ModalFilterCity open={modalFilterCityIsOpen} handleClose={handleCloseModalFilterCity} unidadeId={unidadeId} setUnidadeId={setUnidadeId} setModalFilterCityIsOpen={setModalFilterCityIsOpen}/>
      {children}
    </SubscriptionContex.Provider>
  )
}

const useSubscription = () => {
  const context = useContext(SubscriptionContex)

  return context
}

export {
  SubscriptionProvider,
  useSubscription
}
