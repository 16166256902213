import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import { CursoViewModel } from '../../../_types'

import { Container, Tag } from './styles'

interface CardCourseProps {
  course: CursoViewModel;
  handleClickSubscription: (course: CursoViewModel) => void;
}

export function CardCourse({ course, handleClickSubscription }: CardCourseProps) {
  const cienteEdital = useRef<boolean>(false)
  const [disable, setDisable] = useState(false)

  const checkIsAvailable = () => {
    const { turmas } = course
    if (turmas.length === 0) return false

    const dataHoje = new Date().setHours(0, 0, 0, 0)
    const turmasAvailable = turmas.filter(turma => {
      if (turma.situacao === true) {
        const dtInicio = new Date(turma.dataInicioInscricao).setHours(0, 0, 0, 0)
        const dtFim = new Date(turma.dataFimInscricao).setHours(0, 0, 0, 0)
        if (dtInicio <= dataHoje && dtFim >= dataHoje) {
          return true
        }
      }
      return false
    })

    return turmasAvailable.length > 0
  }

  useEffect(() => {
      setDisable(!checkIsAvailable())
  }, [course])

  const handleClick = () => {
    if (!checkIsAvailable()) return;

    if (!cienteEdital.current) {
      toast.warn('É necessário declarar ciencia sobre o edital para continuar')
      return;
    }
    handleClickSubscription(course)
  }

  const handleCheckTerms = (event: any) => {
    cienteEdital.current = event.target.checked
  }

  const handleClickDownload = () => {
    window.open(course.urlEdital)
  }

    return (
      <Container>
        <h3>
          <span>Curso</span>
          <strong>{course.nome}</strong>
          {
            //disable ? <Tag unavailable>Indisponível</Tag>
            disable ? <Tag unavailable>Vagas Esgotadas</Tag>
              : <Tag>Inscrições Abertas</Tag>
          }
        </h3>
        <span>Unidade: {(course.unidade.nome.replace("CEP ", "")).charAt(0) + (course.unidade.nome.replace("CEP ", "").toLowerCase()).slice(1)}</span>
        <hr />
        <button
          type="button"
          disabled={disable}
          className="btn-download"
          onClick={handleClickDownload}
        >
          <CloudDownloadIcon />Baixe o Edital
        </button>
        <div className="check">
          <input
            disabled={disable}
            type="checkbox"
            name={`checkbox-${course.id}`}
            id={`checkbox-${course.id}`}
            onClick={handleCheckTerms}
          />
          <label htmlFor={`checkbox-${course.id}`}>Li, estou ciente sobre o edital</label>
        </div>
        <hr />
        <button
          type="button"
          disabled={disable}
          className="btn-subscription"
          onClick={() => handleClick()}
        >
          Inscreva-se
        </button>
      </Container>
    )
  }
