import DeleteIcon from '@material-ui/icons/Delete'
import { Container } from './styles'

import { enumParentesco, enumFonteRenda } from '../../../../config/enums'
import { ComposicaoRendaFamiliaViewModel } from '../../../../_types'

interface PersonCardProps {
  pessoaIndex: number;
  pessoaRenda: ComposicaoRendaFamiliaViewModel;
  removePessoa: (pessoaIndex: number) => void;
  disabled: boolean;
}

export function PersonCard({ pessoaIndex, pessoaRenda, removePessoa, disabled }: PersonCardProps) {
  const { nome, parentesco, fonteRenda, valorRenda } = pessoaRenda

  const labelParentesco = enumParentesco.find(p => p.value === parentesco)?.label
  const labelFonteRenda = enumFonteRenda.find(f => f.value === fonteRenda)?.label

  const handleClick = () => {
    removePessoa(pessoaIndex)
  }

  return (
    <Container>
      <p>Nome: <strong>{nome}</strong></p>
      <p>Parentesco: <strong>{labelParentesco}</strong></p>
      <p>Fonte de Renda: <strong>{labelFonteRenda}</strong></p>
      <p>Valor Renda Bruta: <strong>R$ {valorRenda}</strong></p>
      {
        !disabled && <button onClick={handleClick}><DeleteIcon /> Excluir</button>
      }

    </Container>
  )
}
