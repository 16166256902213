import { useHistory } from 'react-router'
import { ModalDefault as Modal } from '../base/default'
import { ModalContent } from '../base/content'
import { Info } from './styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import AssignmentIcon from '@material-ui/icons/Assignment'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'

interface ModalFinishedSubscriptionProps {
  open: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export function ModalFinishedSubscription({ open, setIsOpen }: ModalFinishedSubscriptionProps) {
  const history = useHistory()

  const redirectToProcessList = () => {
    history.push('/processos-seletivos')
    setIsOpen(false)
  }

  const redirectToMySubscription = () => {
    history.push('/minhas-inscricoes')
    setIsOpen(false)
  }

  return (
    <Modal
      fullWidth
      maxWidth="md"
      open={open}
      handleClose={() => { }}
    >
      <ModalContent dividers={false}>
        <Info>
          <CheckCircleIcon fontSize="large" />
          <p><strong> Inscrição enviada com sucesso.</strong></p>
          <p>Acompanhe o andamento através do menu <span>Minhas Inscrições</span>.</p>
          <div className="actions">
            <span>Ir para: </span>
            <div>
              <button
                type="button"
                onClick={redirectToProcessList}
              >
                <AssignmentIcon /> Processos Seletivos
              </button>
              <button
                type="button"
                onClick={redirectToMySubscription}
              >
                <AssignmentIndIcon /> Minhas Inscrições
              </button>
            </div>
          </div>
        </Info>
      </ModalContent>
    </Modal >
  )
}
