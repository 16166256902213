import { ModalDefault as Modal } from '../base/default'
import { ModalActions } from '../base/actions'
import { ModalContent } from '../base/content'
import { ModalTitle } from '../base/title'
import { Button, Message } from './styles'
import { AtendimentosViewModel } from '../../../_types'

interface ModalMessageProps {
  open: boolean;
  messages: AtendimentosViewModel[] | null;
  handleClose: () => void;
}

export function ModalMessage({ open, messages, handleClose }: ModalMessageProps) {
  return (
    <Modal
      fullWidth
      maxWidth="md"
      open={open}
      handleClose={handleClose}
    >
      <ModalTitle handleClose={handleClose}>
        Mensagens
      </ModalTitle>
      <ModalContent dividers>
        {
          messages &&
          messages.map((message, index) => (
            <Message key={index}>
              <span>{`${new Date(message.dataHoraFim).toLocaleString()} - ${message.nome}`}</span>
              <strong>{message.observacao}</strong>
            </Message>
          ))
        }
      </ModalContent>
      <ModalActions>
        <Button
          onClick={handleClose}
        >
          Fechar
        </Button>
      </ModalActions>
    </Modal>
  )
}
