import { ReactNode } from 'react'
import MuiDialogActions from '@material-ui/core/DialogActions'

interface ModalActionsProps {
  children: ReactNode;
}

export function ModalActions({ children }: ModalActionsProps) {
  return (
    <MuiDialogActions>
      {children}
    </MuiDialogActions>
  )
}
