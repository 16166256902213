import styled from 'styled-components'

export const ButtonAlter = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary);
  border: 0;
  border-radius: 3rem;
  color: #fff;
  font-size: 1rem;
  padding: .4rem 1.4rem;
  margin-bottom: 2rem;

  svg {
    margin-right: .4rem;
  }

  transition: filter .2s;
  &:hover {
    filter: brightness(.8);
  }
`
