import { TextField, InputProps } from '@material-ui/core'
import NumberFormat from 'react-number-format'
import { FieldErrors } from 'react-hook-form'
import { Container } from './styles'

interface IInputTextProps extends InputProps {
  label?: string;
  invalid?: FieldErrors;
}

const CustomNumberField = (props: any) => (

  <NumberFormat
    {...props}
    getInputRef={props.inputRef}
    thousandSeparator="."
    decimalSeparator=","
    decimalScale={2}
    fixedDecimalScale={true}
  />
)

export function InputMoney({ label, invalid, ...rest }: IInputTextProps) {
  return (
    <Container>
      <TextField
        label={label}
        fullWidth
        error={!!invalid}
        helperText={invalid?.message}
        InputProps={{
          ...rest,
          inputComponent: CustomNumberField
        }}
        InputLabelProps={{
          shrink: true
        }}
      />
    </Container>
  )
}
