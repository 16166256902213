import { useEffect, useState } from 'react'
import { Container, Top } from './styles'

import { HomeTabs } from '../../components/home-tabs'
import { ListCourses } from '../../components/list-courses'
import { MySubscriptions } from '../../components/my-subscriptions'
import { useHistory, useLocation } from 'react-router'
import { ModalFilterCity } from '../../components/modal/filter-city'
import { courseService } from '../../services/course'

const TAB_LIST_PROCESS = 0
const TAB_MY_SUBSCRIPTION = 1

export function Home() {
  const { pathname } = useLocation()
  const history = useHistory()
  const [tabMenu, setTabMenu] = useState(TAB_LIST_PROCESS)

  // aqui
  const [unidadeId, setUnidadeId] = useState("")
  const [modalFilterCityIsOpen, setModalFilterCityIsOpen] = useState(true)
  const handleCloseModalFilterCity = () => {
    console.log("teste");
    setModalFilterCityIsOpen(false)
  }

  const loadListCourses = async (unidadeId: string) => {
    const { succeeded, data } = await courseService.getUnidadeById(unidadeId)
    if (succeeded && data) {
     // setListCourses(data)
    }
  }

  useEffect(() => {
    console.log("unidadeId: " + unidadeId);
    loadListCourses(unidadeId)
  }, [unidadeId]);


  useEffect(() => {
    if (pathname === '/minhas-inscricoes') {
      setTabMenu(TAB_MY_SUBSCRIPTION)
    } else {
      setTabMenu(TAB_LIST_PROCESS)
    }
  }, [pathname])

  const handleChangeTab = (tab: number) => {
    if (tab === TAB_MY_SUBSCRIPTION) {
      history.push('/minhas-inscricoes')
    } else {
      history.push('/processos-seletivos')
    }
  }

  const CurrentTab = () => {
    if (tabMenu === TAB_LIST_PROCESS) {
      return <ListCourses />
    }
    return <MySubscriptions />
  }
  
  return (
    <Container>
      {/*<ModalFilterCity open={modalFilterCityIsOpen} handleClose={handleCloseModalFilterCity} unidadeId={unidadeId} setUnidadeId={setUnidadeId} />*/}
      <Top>
        <strong>Bem vindo ao portal de inscrições do Programa Senac de Gratuidade</strong>
        <span>Confira abaixo os processos seletivos disponíveis e acompanhe suas inscrições</span>
      </Top>

      <HomeTabs currentTab={tabMenu} handleTabs={handleChangeTab} />
      <CurrentTab />

    </Container>
  )
}
