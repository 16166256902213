import { ReactNode } from 'react'
import { Header } from '../header'
import { Main } from './styles'

interface LayoutProps {
  children: ReactNode
}

export function Layout({ children }: LayoutProps) {
  return (
    <>
      <Header />
      <Main>
        {children}
      </Main>
    </>
  )
}
