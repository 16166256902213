import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  background: #f2f2f5;
  border: 1px solid #bdbdbd;
  border-radius: .4rem;
  padding: .6rem;

  p {
    font-size: .8rem;
    line-height: 1.6rem;
  }

  strong {
    font-size: .9rem;
  }

  button {
    margin: .6rem auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .8rem;
    font-weight: 600;
    color: #da0606;
    border: 0;
    background: none;
    cursor: pointer;

    svg {
      width: 1.2rem;
      height: 1.2rem;
      margin-right: .4rem;
    }

    transition: color .2s;
    &:hover {
      filter: brightness(.8)
    }
  }
`
