import jwtDecode from 'jwt-decode'
import { AuthState } from '../components/../_types'
import { env } from '../config/env'
import { psgApi } from './http'

type DecodedToken = {
  cpf: string;
  email: string;
}

interface ILoginByAuthorizationCode {
  code: string;
  callback: string;
}
export const authService = {
  redirectToLogin: () => {
    window.location.href = `${env.auth.baseUrl}${env.auth.endPointLogin}?client_id=${env.auth.clientId}&response_type=${env.auth.responseType}&scope=${env.auth.scope}&redirect_uri=${env.auth.redirectUri}`;
    return;
  },

  redirectToLogout: () => {
    localStorage.clear();
    window.location.href = `${env.auth.baseUrl}${env.auth.endPointLogout}?postLogout=${env.auth.logoutUrl}`;
    return;
  },

  loginByAuthorizationCode: async ({ code, callback }: ILoginByAuthorizationCode) => {
    const response = await psgApi
      .post(`${env.psg.apiUrl}/auth`, { code, callback }, { activeSpinner: true });

    if (response.status === 200) {
      const user = _decodeToken(response.data.accessToken)
      const content = {
        ...response.data,
        user
      }
      localStorage.setItem("@authPSG", JSON.stringify(content))
      window.location.href = env.auth.homeUrl
    }
    return response;
  },

  refreshToken: async (refreshToken: string) => {
    const response = await psgApi
      .post<AuthState>(`${env.psg.apiUrl}/auth/refresh-token`, { refreshToken })

    if (response.status === 200) {
      const user = _decodeToken(response.data.accessToken)
      const content = {
        ...response.data,
        user
      }
      return content
    }

    return null;
  }
}

const _decodeToken = (token: string): DecodedToken => {
  const { cpf, email } = jwtDecode<DecodedToken>(token)
  return { cpf, email }
}
