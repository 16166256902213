import { CidadeViewModel, TCepInfo, TUf } from '../components/../_types'
import { psgApi, TResponse } from './http'

export const localidadeService = {
  loadUF: async (): Promise<TResponse<TUf[]>> => {
    try {
      const response = await psgApi.get('/estado')

      if (!response || response.status !== 200) {
        throw new Error('Não foi possível carregar a lista de UF')
      }

      return {
        succeeded: true,
        data: response.data,
        message: null
      }
    } catch (error) {
      return {
        succeeded: false,
        data: null,
        message: error.message
      }
    }
  },

  loadCities: async (uf: string): Promise<TResponse<CidadeViewModel[]>> => {
    try {
      const response = await psgApi.get(`/cidade/${uf}`)

      if (!response || response.status !== 200) {
        throw new Error('Não foi possível carregar a lista de cidades')
      }

      return {
        succeeded: true,
        data: response.data,
        message: null
      }
    } catch (error) {
      return {
        succeeded: false,
        data: null,
        message: error.message
      }
    }
  },

  loadCepInfo: async (cep: string): Promise<TResponse<TCepInfo>> => {
    try {
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`)

      if (response && response.ok) {
        const data = await response.json()

        if (data.erro) {
          throw new Error('Não foi possível carregar o endereço. Verifique se o CEP está correto ou complete o endereço manualmente')
        }

        const cepInfo: TCepInfo = {
          cep: data.cep,
          codigoIBGE: data.ibge,
          bairro: data.bairro,
          logradouro: data.logradouro,
          uf: data.uf
        }

        return {
          succeeded: true,
          data: cepInfo,
          message: null
        }
      }

      throw new Error('Não foi possível carregar o endereço. Verifique se o CEP está correto ou complete o endereço manualmente')
    } catch (error) {
      return {
        succeeded: false,
        data: null,
        message: error.message
      }
    }
  }
}
