import InputAdornment from '@material-ui/core/InputAdornment'
import DoneIcon from '@material-ui/icons/Done'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { InputText, InputSelect, InputMoney } from '../../../inputs'

import { TPessoaRenda } from '../../../../_types'
import { enumParentesco, enumFonteRenda } from '../../../../config/enums'

import { Grid } from '../../default-styles'
import { Button, ErrorMessage } from './styles'

const schemaValidation = yup.object().shape({
  nome: yup.string()
    .required('Informe o nome completo'),
  parentesco: yup.number()
    .positive('Informe o grau de parentesco')
    .required('Informe o grau de parentesco'),
  fonteRenda: yup.number()
    .positive('Informe a fonte de renda')
    .required('Informe a fonte de renda'),
  valorRenda: yup.string()
    .typeError('Valor inválido')
    .required('Informe o valor da renda bruta')
})

type FormData = {
  nome: string;
  parentesco: number;
  fonteRenda: number;
  valorRenda: string;
};

interface PersonFormProps {
  handlePessoaRenda: (pessoaRenda: TPessoaRenda) => void;
  error: string;
  disabled: boolean;
}

export function PersonForm({ handlePessoaRenda, error, disabled }: PersonFormProps) {
  const { register, handleSubmit, errors, reset } = useForm<FormData>({
    resolver: yupResolver(schemaValidation)
  })

  const onSubmit = handleSubmit(data => {
    handlePessoaRenda(data)
    reset()
  })

  return (
    <>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <Grid
        as="form"
        marginTop
        marginBottom
        onSubmit={onSubmit}
      >
        <InputText
          name="nome"
          label="Nome Completo"
          inputRef={register}
          invalid={errors.nome}
        />
        <InputSelect
          name="parentesco"
          label="Parentesco"
          inputRef={register}
          options={enumParentesco}
          invalid={errors.parentesco}
        />
        <InputSelect
          name="fonteRenda"
          label="Fonte de Renda"
          inputRef={register}
          options={enumFonteRenda}
          invalid={errors.fonteRenda}
        />
        <InputMoney
          name="valorRenda"
          label="Valor Renda Bruta"
          inputRef={register}
          invalid={errors.valorRenda}
          startAdornment={
            <InputAdornment position="start">R$</InputAdornment>
          }
        />
        <div></div>
        <Button
          type="submit"
          disabled={disabled}
        >
          <DoneIcon /> Adicionar Pessoa
        </Button>
      </Grid>
    </>
  )
}
