import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Top = styled.h1`
  margin-top: 3rem;
  width: 100%;
  background: #efecec;
  padding: .5rem;
  border-left: .6rem solid #dedede;

  strong {
    display: block;
    font-weight: 500;
    font-size: 1.2rem;
    margin-bottom: .6rem;
  }

  span {
    display: block;
    font-size: .9rem;
    font-weight: 400;
    color: #6d6d6d;
  }
`
