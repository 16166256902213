import { TextField, InputProps } from '@material-ui/core'
import MaskedInput from 'react-text-mask'
import { FieldErrors } from 'react-hook-form'
import { Container } from './styles'

interface IInputTextProps extends InputProps {
  label?: string;
  invalid?: FieldErrors;
}

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function TextMaskCpf(props: TextMaskCustomProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[0-9]/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask={false}
    />
  );
}

export function InputPhone({ label, invalid, ...rest }: IInputTextProps) {
  return (
    <Container>
      <TextField
        label={label}
        fullWidth
        error={!!invalid}
        helperText={invalid?.message}
        InputProps={{
          ...rest,
          inputComponent: TextMaskCpf as any
        }}
        InputLabelProps={{
          shrink: true
        }}
      />
    </Container>
  )
}
