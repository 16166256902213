import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 4px;
  padding: 2rem;
  text-decoration: none;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.12);
  transition: all 0.3s ease-out;

  h3 {
    margin-top: 1rem;
  }

  span {
    font-size: 1rem;
    display: block;
    font-weight: 400;
    text-align: center;
  }

  strong {
    font-size: 1.4rem;
    display: block;
    font-weight: 600;
    text-align: center;
    line-height: 2rem;
  }

  hr {
    width: 100%;
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.1);
  }

  div.check {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: .9rem;

    label {
      cursor: pointer;
    }

    input {
      width: 1rem;
      height: 1rem;
      margin-right: .6rem;
      cursor: pointer;
    }
  }

  button.btn-download {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #004a8d;
    font-size: 0.8rem;
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
    border-radius: 3rem;
    text-align: center;
    padding: 0.3rem 0.6rem;
    color: #004a8d;
    text-transform: uppercase;
    font-weight: 500;
    background: none;

    transition: background .4s;

    svg {
      color: #004a8d;
      margin-right: 1rem;
      width: 1.2rem;
      height: 1.2rem;
    }
  }

  button.btn-download:enabled:hover {
    background: #004a8d;
    color: #fff;

    svg {
      color: #fff;
    }
  }

  button.btn-subscription {
    border: 0;
    border-radius: 3rem;
    background: #55c539;
    font-size: 1.2rem;
    color: var(--text);

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: .8rem;
    border-radius: 3rem;
    text-align: center;
    padding: 0.3rem 0.6rem;
    text-transform: uppercase;
    font-weight: 500;
    transition: filter .2s;
  }

  button.btn-subscription:enabled:hover {
    filter: brightness(0.8);
  }

  button.btn-subscription:disabled {
    background: #9da59b;
  }
`
type TagProps = {
  unavailable?: boolean;
}

export const Tag = styled.span<TagProps>`
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  padding: 0.1rem 0.4rem;
  background: ${props => props.unavailable ? '#da0606' : '#f7941d'};
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: 400;
  color: var(--text);

  @media (min-width: 600px) {
    font-size: .8rem;
  }
`
