import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import AddIcon from '@material-ui/icons/Add'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { InputText, InputSelect } from '../../inputs'
import { PersonCard } from './person-card'
import { PersonForm } from './person-form'
import { env } from '../../../config/env'
import { enumMoradia } from '../../../config/enums'
import { useAuth } from '../../../hooks/auth'
import { useSubscription } from '../../../hooks/subscription'
import { infoFamiliaService } from '../../../services/info-familia'
import { schemaValidation } from './schema-validation'
import {
  TPessoaRenda,
  FormDataFamily,
  ComposicaoRendaFamiliaViewModel,
  InfoFamiliaViewModel,
} from '../../../_types'

import { Container, Grid, ButtonNext } from '../default-styles'
import { ButtonAlter } from './styles'
import { helper } from '../../../helpers'

export function Family(props: any) {
  const { isActive, nextStep } = props // Props injetadas pelo StepWizard

  const { authState } = useAuth()
  const { selectedSubscription } = useSubscription()

  const [composicaoRendaFamilia, setComposicaoRendaFamilia] = useState<ComposicaoRendaFamiliaViewModel[]>([])
  const [editing, setEditing] = useState<boolean>(false)
  const [errorPersonForm, setErrorPersonForm] = useState<string>('')

  const { register, handleSubmit, errors, reset } = useForm<FormDataFamily>({
    resolver: yupResolver(schemaValidation(editing))
  })

  const initFormValues = async () => {
    if (selectedSubscription.infoFamiliar) {
      const { tipoMoradia, qtdPessoasResidem, qtdPossuemRenda } = selectedSubscription.infoFamiliar
      reset({ tipoMoradia, qtdPessoasResidem, qtdPossuemRenda })
      setComposicaoRendaFamilia(
        selectedSubscription.infoFamiliar.composicoesRendaFamiliar || []
      )
      setEditing(true)
      return
    }
    const { succeeded, data } = await infoFamiliaService.getDefault()
    if (succeeded && data) {
      reset({ ...data })
      setComposicaoRendaFamilia(data.composicoesRendaFamiliar || [])
      setEditing(true)
    }
  }

  useEffect(() => {
    if (isActive) {
      initFormValues()
      window.scrollTo(0, 0)
    }
  }, [isActive])

  const addPessoaRenda = (pessoaRenda: TPessoaRenda) => {
    if (errorPersonForm) {
      setErrorPersonForm('')
    }
    setComposicaoRendaFamilia([
      ...composicaoRendaFamilia,
      {
        ...pessoaRenda,
        valorRenda: helper.unMaskMoney(String(pessoaRenda.valorRenda))
      }
    ])
  }

  const removePessoaRenda = (pessoaIndex: number) => {
    if (errorPersonForm) {
      setErrorPersonForm('')
    }
    const pessoasUpdated = composicaoRendaFamilia.filter((_, index) => index !== pessoaIndex)
    setComposicaoRendaFamilia(pessoasUpdated)
  }

  const rendaFamiliaIsOk = (data: FormDataFamily) => {
    if (data.qtdPossuemRenda > composicaoRendaFamilia.length) {
      setErrorPersonForm('Clique em Adicionar Pessoa para incluir as pessoas que compôe a renda familiar')
      return false
    }

    if (data.qtdPossuemRenda < composicaoRendaFamilia.length) {
      setErrorPersonForm('O número de pessoas adicionadas não corresponde a quantidade de pessoas que compõe a renda')
      return false
    }

    const rendaTotal = composicaoRendaFamilia
      .map(pessoa => pessoa.valorRenda)
      .reduce((total, valorRenda) => Number(total) + Number(valorRenda))

    const { salarioMinimo, limiteSalarioMinimoPerCapita } = env.psg.parameters
    const limiteRendaPerCapita = salarioMinimo * limiteSalarioMinimoPerCapita
    const rendaPerCapita = Number(rendaTotal) / data.qtdPessoasResidem

    if (rendaPerCapita > limiteRendaPerCapita) {
      setErrorPersonForm('O PSG destina-se a pessoas de baixa renda, cuja renda per capita não ultrapasse 02 (dois) salários mínimos')
      return false
    }
    return true
  }

  const startNewInfoFamily = () => {
    reset({})
    setComposicaoRendaFamilia([])
    setEditing(false)
  }

  const onSubmit = handleSubmit(async dataForm => {
    if (!rendaFamiliaIsOk(dataForm)) return;

    if (editing) {
      return nextStep()
    }

    const infoFamiliarForm = {
      ...dataForm,
      cpf: authState.user.cpf,
      composicoesRendaFamiliar: composicaoRendaFamilia
    } as InfoFamiliaViewModel

    const { succeeded, message, data } = await infoFamiliaService.save(infoFamiliarForm)

    if (!succeeded || !data) {
      toast.error(message)
      return
    }

    nextStep()
  })

  return (
    <Container>
      <h3>Renda Familiar</h3>
      <Grid
        as="form"
        marginTop
        marginBottom
        onSubmit={onSubmit}
      >
        <InputSelect
          name="tipoMoradia"
          label="Tipo de moradia"
          inputRef={register}
          invalid={errors.tipoMoradia}
          options={enumMoradia}
          disabled={editing}
        />
        <InputText
          name="qtdPessoasResidem"
          type="number"
          label="Qtd. pessoas residem na moradia"
          inputRef={register}
          invalid={errors.qtdPessoasResidem}
          disabled={editing}
        />
        <InputText
          name="qtdPossuemRenda"
          type="number"
          label="Qtd. pessoas compõe a renda"
          inputRef={register}
          invalid={errors.qtdPossuemRenda}
          disabled={editing}
        />
        {
          !!composicaoRendaFamilia.length &&
          <ButtonNext
            type="submit"
          >
            Avançar <ArrowForwardIcon />
          </ButtonNext>
        }
      </Grid>

      <h3>Pessoas que compõe a Renda Familiar</h3>
      {
        !editing &&
        <PersonForm
          handlePessoaRenda={addPessoaRenda}
          error={errorPersonForm}
          disabled={editing}
        />
      }

      {
        composicaoRendaFamilia && (
          <Grid
            marginTop
            marginBottom
          >
            {composicaoRendaFamilia.map((pessoa, index) =>
              <PersonCard
                key={index}
                pessoaIndex={index}
                pessoaRenda={pessoa}
                removePessoa={removePessoaRenda}
                disabled={editing}
              />
            )}
          </Grid>
        )
      }
      {
        editing && !selectedSubscription.infoFamiliar &&
        <Grid marginBottom>
          <div></div>
          <ButtonAlter
            type="button"
            onClick={startNewInfoFamily}
          >
            <AddIcon />Nova Renda Familiar
          </ButtonAlter>
        </Grid>
      }
    </Container>
  )
}
