import {
  useState,
  useCallback,
  useContext,
  createContext,
  ReactNode
} from 'react'
import { AuthState } from '../components/../_types'

type AuthContextData = {
  logged: boolean;
  authState: AuthState;
  signIn: (token: AuthState) => void;
  signOut: () => void;
}

interface AuthProviderProps {
  children: ReactNode
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export function AuthProvider({ children }: AuthProviderProps) {
  const [authState, setAuthState] = useState<AuthState>(() => {
    const authPSG = localStorage.getItem('@authPSG');

    if (authPSG) {
      const token = JSON.parse(authPSG)
      return { ...token };
    }
    return {} as AuthState
  })

  const signIn = useCallback(authPSG => {
    localStorage.setItem("@authPSG", JSON.stringify(authPSG));
    setAuthState({ ...authPSG })
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@authPSG');
    setAuthState({} as AuthState)
  }, []);

  return (
    <AuthContext.Provider
      value={{
        logged: !!authState.accessToken,
        authState,
        signIn,
        signOut
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) throw new Error('useAuth must be used within an AuthProvider');

  return context;
}
