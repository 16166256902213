import { Switch, Route, BrowserRouter } from 'react-router-dom'

import { Login } from '../components/login'
import { Home } from '../pages/home'
import { Subscription } from '../pages/subscription'
import { Layout } from '../components/layout'
import { useAuth } from '../hooks/auth'

export function Router() {
  const { logged } = useAuth()

  const ActiveRoutes = () => {
    if (logged) {
      return (
        <Layout>
          <Route path="/" exact component={Home} />
          <Route path="/processos-seletivos" exact component={Home} />
          <Route path="/minhas-inscricoes" exact component={Home} />
          <Route path="/inscricao/:id?" exact component={Subscription} />
          <Route path="/auth" exact component={Home} />
        </Layout>
      )
    }
    return <Route path="/" component={Login} />
  }

  return (
    <BrowserRouter>
      <Switch>
        <ActiveRoutes />
      </Switch>
    </BrowserRouter>
  )
}
