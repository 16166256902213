export const SITUACAO_EM_CADASTRO = 1
export const SITUACAO_AGUARDANDO_ANALISE = 2
export const SITUACAO_EM_ANALISE = 3
export const SITUACAO_AGUARDANDO_RETORNO_CLIENTE = 4
export const SITUACAO_APROVADO = 5
export const SITUACAO_REPROVADO = 6
export const SITUACAO_CANCELADO = 7
export const SITUACAO_APROVADO_FINAL = 8
export const SITUACAO_REPROVADO_FINAL = 9
export const SITUACAO_RETORNADO_SOLICITACAO = 10;
export const SITUACAO_PERIODO_ENCERRADO = 11;
export const SITUACAO_CANCELADO_VIA_CONTATO = 12;

export const enumSituacao = [
  { id: SITUACAO_EM_CADASTRO, descricao: "Em Cadastro", color: '#42a5f5' },
  { id: SITUACAO_AGUARDANDO_ANALISE, descricao: "Aguardando Analise", color: '#ffd600' },
  { id: SITUACAO_EM_ANALISE, descricao: "Em Analise", color: '#f7941d' },
  { id: SITUACAO_AGUARDANDO_RETORNO_CLIENTE, descricao: "Aguardando Retorno Cliente", color: '#80cbc4' },
  { id: SITUACAO_REPROVADO, descricao: "Reprovado Online", color: '#7e57c2' },
  { id: SITUACAO_APROVADO, descricao: "Aprovado Online", color: '#55c539' },
  { id: SITUACAO_CANCELADO, descricao: "Cancelado", color: '#c62828' },
  { id: SITUACAO_APROVADO_FINAL, descricao: "Aprovada Final", color: '#55c539' },
  { id: SITUACAO_REPROVADO_FINAL, descricao: "Reprovada Final", color: '#7e57c2' },
  { id: SITUACAO_RETORNADO_SOLICITACAO, descricao: "Aguardando Nova Analise", color: '#ffd600' },
  { id: SITUACAO_PERIODO_ENCERRADO, descricao: "Período Encerrado", color: '#c62828' },
  { id: SITUACAO_CANCELADO_VIA_CONTATO, descricao: "Cancelado via Contato", color: '#c62828' },
];

export const isSitucaoEdit = (status: number) => {
  const situacaoEdit = [
    SITUACAO_EM_CADASTRO,
    SITUACAO_AGUARDANDO_ANALISE,
    SITUACAO_AGUARDANDO_RETORNO_CLIENTE,
  ]
  return situacaoEdit.includes(status)
}

export const isSitucaoCancelar = (status: number) => {
  const situacaoCancel = [SITUACAO_EM_CADASTRO, SITUACAO_AGUARDANDO_ANALISE]
  return situacaoCancel.includes(status)
};

export const getLabelSituacao = (id: number) => {
  let curretSituacao = enumSituacao.filter((s) => s.id === id)

  if (curretSituacao && curretSituacao.length > 0) {
    return curretSituacao[0].descricao
  }

  return ''
};
