import Backdrop from '@material-ui/core/Backdrop'
import PulseLoader from 'react-spinners/PulseLoader'

interface SpinnerProps {
  ative: boolean;
}

export function Spinner({ ative }: SpinnerProps) {
  return (
    <Backdrop
      open={ative}
      style={{
        zIndex: 9999,
        color: '#fff'
      }}
    >
      <PulseLoader size={25} color="#f7941d" />
    </Backdrop>
  )
}
