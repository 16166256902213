import { Link } from 'react-router-dom'
import logo from '../../assets/images/logo-colorida.png'
import { MenuLarge } from './menu-large'
import { MenuSmall } from './menu-small'

import { Container, Content } from './styles'

export function Header() {
  return (
    <Container className="container">
      <Content>
        <Link to="/">
          <img src={logo} alt="Senac" />
        </Link>
        <div className="menu">
          <MenuLarge />
        </div>
        <div className="menu-mobile">
          <MenuSmall />
        </div>
      </Content>
    </Container>
  )
}
