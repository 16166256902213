export const DADOS_PESSOAIS_CANDIDATO_FRENTE = 1
export const DADOS_PESSOAIS_CANDIDATO_VERSO = 2
export const CPF_CANDIDATO = 3
export const DECLARACAO_ESCOLAR = 4
export const COMPROVANTE_RENDA = 5
export const COMPROVANTE_ENDERECO = 6
export const DADOS_PESSOAIS_RESPONSAVEL_FRENTE = 7
export const DADOS_PESSOAIS_RESPONSAVEL_VERSO = 8
export const CPF_RESPONSAVEL = 9

export const enumDocumentoAnexoMenorIdade = [
  { required: true, value: DADOS_PESSOAIS_CANDIDATO_FRENTE, label: 'Dados Pessoais do Candidato - Frente' },
  { required: true, value: DADOS_PESSOAIS_CANDIDATO_VERSO, label: 'Dados Pessoais do Candidato - Verso', },
  { required: true, value: CPF_CANDIDATO, label: 'CPF Candidato' },
  { required: false, value: DECLARACAO_ESCOLAR, label: 'Declaração Escolar (Obrigatório para Técnicos)' },
  { required: true, value: COMPROVANTE_ENDERECO, label: 'Comprovante de Endereço' },
  { required: true, value: DADOS_PESSOAIS_RESPONSAVEL_FRENTE, label: 'Dados Pessoais do Responsável - Frente' },
  { required: true, value: DADOS_PESSOAIS_RESPONSAVEL_VERSO, label: 'Dados Pessoais do Responsável - Verso' },
  { required: true, value: CPF_RESPONSAVEL, label: 'CPF Responsável' },
]

export const enumDocumentoAnexo = [
  { required: true, value: DADOS_PESSOAIS_CANDIDATO_FRENTE, label: 'Dados Pessoais do Candidato - Frente' },
  { required: true, value: DADOS_PESSOAIS_CANDIDATO_VERSO, label: 'Dados Pessoais do Candidato - Verso' },
  { required: true, value: CPF_CANDIDATO, label: 'CPF' },
  { required: false, value: DECLARACAO_ESCOLAR, label: 'Declaração Escolar (Obrigatório para Técnicos)' },
  { required: true, value: COMPROVANTE_ENDERECO, label: 'Comprovante de Endereço' },
]


export const DOCUMENTO_SITUACAO_ENVIANDO = -1
export const DOCUMENTO_SITUACAO_AGUARDANDO = 0
export const DOCUMENTO_SITUACAO_APROVADO = 1
export const DOCUMENTO_SITUACAO_REPROVADO = 2


export const canEditDoc = (status: number) => {
  const statusEdit = [DOCUMENTO_SITUACAO_ENVIANDO, DOCUMENTO_SITUACAO_REPROVADO]
  return statusEdit.includes(status)
}
