import * as yup from 'yup'

export const schemaContactValidation = yup.object().shape({
  cep: yup.string()
    .trim()
    .min(9, 'Informe um CEP válido')
    .required('Informe o CEP'),
  logradouro: yup.string()
    .required('Informe o endereço'),
  numero: yup.string()
    .max(10, 'Este campo permite no máximo 10 caracteres'),
  complemento: yup.string()
    .required('Ex.: Quadra / Lote / Andar / Apto'),
  bairro: yup.string()
    .required('Informe o bairro'),
  codigoIBGE: yup.string()
    .notOneOf(['-1'], 'Informe a cidade')
    .required('Informe a cidade'),
  uf: yup.string()
    .notOneOf(['-1'], 'Informe o estado')
    .length(2, 'Informe o estado')
    .required('Informe o estado'),
  telefonePrincipal: yup.string()
    .required('Informa um telefone para contato'),
  email: yup.string().email('Informe um email válido')
    .required('Informe o email para contato')
})
