import { ModalDefault as Modal } from '../base/default'
import { ModalActions } from '../base/actions'
import { ModalContent } from '../base/content'
import { ModalTitle } from '../base/title'
import { Info, ButtonContinue } from './styles'
import { useSubscription } from '../../../hooks/subscription'
import { enumEscolaridade } from '../../../config/enums'

interface ModalStartSubscriptionProps {
  open: boolean;
  handleClose: () => void;
  handleClickOk: () => void;
}

export function ModalStartSubscription({ open, handleClose, handleClickOk }: ModalStartSubscriptionProps) {
  const { selectedCourse } = useSubscription()

  const idadeMinima = selectedCourse.idadeMinima
  const escolaridadeMinima = enumEscolaridade.find(e => e.value === selectedCourse.escolaridadeMinima)?.label

  return (
    <Modal
      fullWidth
      maxWidth="md"
      open={open}
      handleClose={handleClose}
    >
      <ModalTitle handleClose={handleClose}>
        Informações para Inscrição
      </ModalTitle>
      <ModalContent dividers>
        <Info>
          <h3>
            Ao finalizar a inscrição, você precisará anexar os documentos listados abaixo:
          </h3>
          <ul>
            <li><strong>DOCUMENTOS DO CANDIDATO E DO RESPONSÁVEL (para menores de 18 anos)</strong></li>
            <li>Documento Pessoal (RG, Certidão de Nascimento ou CNH)</li>
            <li>CPF</li>
            <li>Comprovante de Endereço <span>(somente comprovante de endereço dos últimos 3 meses considerando a data atual)</span></li>
          </ul>
          <ul>
            <li><strong>REQUISITOS PARA O CURSO</strong></li>
            <li>Idade mínima: {idadeMinima}</li>
            <li>Escolaridade mínima: {escolaridadeMinima}</li>
          </ul>
          <p><strong>ATENÇÃO: As inscrições estão destinadas apenas para pessoas residentes no Estado de Goiás</strong></p>
        </Info>
      </ModalContent>
      <ModalActions>
        <ButtonContinue
          onClick={handleClickOk}
        >
          Continuar
        </ButtonContinue>
      </ModalActions>
    </Modal >
  )
}
