import styled from 'styled-components'

export const Content = styled.div`
  width: 100%;
  margin: 3rem auto;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fit, 320px);
  justify-content: center;
`
