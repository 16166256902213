import { ModalDefault as Modal } from '../base/default';
import { ModalActions } from '../base/actions';
import { ModalContentText } from '../base/content-text';
import { ModalTitle } from '../base/title';
import { Button } from './styles';
import { toast } from 'react-toastify';

interface ModalStartSubscriptionProps {
  open: boolean;
  title: string;
  content: string;
  canPrint: boolean;
  handleClose: () => void;
}

export function ModalTerms({ open, title, content, handleClose, canPrint }: ModalStartSubscriptionProps) {
  const handleClickPrint = () => {
    try {
      let IEwindow = window.open()
      if (!IEwindow)
        throw new Error()

      IEwindow.document.body.innerHTML = content

      setTimeout(() => {
        if (IEwindow) {
          IEwindow.document.write(content)
          IEwindow.print()
        }
      }, 50)

      IEwindow.document.close()
    } catch (e) {
      toast
        .warn('O navegador bloqueou a abertura da página. Verifique o bloqueio de pop-up e tente novamente.')
    }
  }

  return (
    <Modal
      fullWidth
      maxWidth="md"
      open={open}
      handleClose={handleClose}
    >
      <ModalTitle handleClose={handleClose}>
        {title}
      </ModalTitle>
      <ModalContentText dividers content={content} />
      <ModalActions>
        {
          canPrint &&
          <Button
            className="print"
            onClick={handleClickPrint}
          >
            Imprimir
          </Button>
        }
        <Button
          className="close"
          onClick={handleClose}
        >
          Fechar
        </Button>
      </ModalActions>
    </Modal >
  )
}
