import styled from 'styled-components'

export const Container = styled.div`
  max-width: 1120px;
  margin: 0 auto;

  .MuiStepIcon-root {
    width: 2.5rem;
    height: 2.5rem;
  }

  .MuiStepIcon-root.MuiStepIcon-active,
  .MuiStepIcon-root.MuiStepIcon-completed{
    color: #f7941d;
  }

  .MuiStepConnector-alternativeLabel {
    top: 22px;
  }

  @media (max-width: 500px) {
    .MuiStepper-root {
      padding: unset;
    }
    .MuiStepConnector-line {
      display: none;
    }
    .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
      font-size: .8rem;
    }
    .MuiStepIcon-root {
      width: 2rem;
      height: 2rem;
    }
  }
`
