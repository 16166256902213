import styled from 'styled-components'

export const Container = styled.div`
  max-width: 920px;
  margin: 0 auto;
`

export const Document = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: .9rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(0,0,0,.1);

  > div {
    padding-left: 1.2rem;

    span {
      display: block;
      font-size: .9rem;
      font-weight: 600;
      text-transform: uppercase;
    }

    strong {
      font-size: .9rem;
      font-weight: 400;
      text-transform: lowercase;
      display: flex;
      align-items: center;

      svg {
        margin-right: .4rem;
        color: #676767;
      }
    }
  }

  @media (max-width: 600px) {
    flex-direction: column-reverse;

    > div {
      text-align: center;
      padding-left: unset;
    }

    strong {
      justify-content: center;
    }
  }
`

interface InputFileProps {
  disabled?: boolean;
}

export const InputFile = styled.label<InputFileProps>`
  input {
    display: none;
  }

  svg {
    margin-right: .6rem;
  }

  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14rem;
  cursor: pointer;
  background: var(--primary);
  border: 0;
  border-radius: 3rem;
  color: #fff;
  font-size: 1rem;
  padding: .4rem 1rem;
  opacity: ${props => props.disabled ? 0.2 : 1};
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};

  transition: filter .2s;
  &:hover {
    filter: brightness(.8);
  }

  @media (max-width: 600px) {
    margin-top: .5rem;
  }
`

export const CheckCient = styled.div`
  margin-top: .8rem;
  font-size: .9rem;
  display: flex;
  align-items: center;

  button.link{
    background: none;
    border: 0;
    margin-left: .3rem;
    color: var(--primary);
    font-weight: 600;
    font-size: .9rem;

    &:hover {
      text-decoration: underline;
    }
  }
`

export const ButtonFinish = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 3rem;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding: .6rem 3rem;
    border: 0;
    font-size: 1.1rem;
    background: #f7941d;
    color: #fff;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

    svg {
      margin-left: .6rem;
    }

    transition: filter .2s;
    &:hover {
      filter: brightness(0.8);
    }
  }
`
