import { TextField, InputProps } from '@material-ui/core'
import { FieldErrors } from 'react-hook-form'
import { Container } from './styles'

interface IInputTextProps extends InputProps {
  label?: string;
  invalid?: FieldErrors;
}

export function InputText({ label, invalid, ...rest }: IInputTextProps) {
  return (
    <Container>
      <TextField
        label={label}
        fullWidth
        error={!!invalid}
        helperText={invalid?.message}
        InputProps={{
          ...rest
        }}
        InputLabelProps={{
          shrink: true
        }}
      />
    </Container>
  )
}
