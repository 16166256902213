import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  max-width: 720px;
  margin: 0 auto 3rem;

  background: #e5e8f4;
  padding: 2rem 3rem 5.8rem;
  border-radius: 1rem;

  @media (max-width: 600px) {
    padding: 2rem 2rem 5.8rem;
  }
`

interface GridCustomProps {
  readonly inline?: boolean;
  readonly marginTop?: boolean;
  readonly marginBottom?: boolean;
}

export const Grid = styled.div<GridCustomProps>`
  margin-top: ${props => props.marginTop ? '3rem' : '0'};
  margin-bottom: ${props => props.marginBottom ? '2rem' : '0'};
  grid-template-columns: ${props => props.inline ? '1fr' : '1fr 1fr'};
  display: grid;
  grid-gap: 2rem;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`

export const ButtonNext = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  padding: .6rem;
  right: 0;
  bottom: 3rem;
  border: 0;
  font-size: 1.1rem;
  background: #f7941d;
  color: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

  svg {
    margin-left: .6rem;
  }

  transition: filter .2s;
  &:hover {
    filter: brightness(0.8);
  }
`
