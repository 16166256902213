import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { InputText, InputCep, InputSelect, InputPhone } from '../../inputs'
import { schemaContactValidation } from './schema-validation'
import { localidadeService } from '../../../services/localidade'
import { useSubscription } from '../../../hooks/subscription'
import { subscriptionService } from '../../../services/subscription'
import {
  TSelectOption,
  FormDataContact,
  CidadeViewModel,
  EstadoViewModel,
  ContatoViewModel,
  InscricaoViewModel
} from '../../../_types'

import { Container, Grid, ButtonNext } from '../default-styles'

export function Contact(props: any) {
  const { isActive, nextStep } = props // Props injetadas pelo StepWizard

  const lastCepSearch = useRef<string>()
  const [editing, setEditing] = useState<boolean>(false)
  const [loadingCep, setLoadingCep] = useState<boolean>(false)
  const [listUF, setListUF] = useState<TSelectOption[]>([])
  const [listCities, setListCities] = useState<TSelectOption[]>([])
  const [enderecoContato, setEnderecoContato] = useState<Partial<FormDataContact>>({
    logradouro: '',
    bairro: '',
    codigoIBGE: '',
    uf: ''
  } as FormDataContact)

  const { selectedSubscription, updateSubscriptionState } = useSubscription()
  const { register, handleSubmit, errors, reset, setError, clearErrors } = useForm<FormDataContact>({
    resolver: yupResolver(schemaContactValidation)
  })

  const initFormValues = async () => {
    if (listUF.length === 0) {
      await loadUF()
    }
    const { candidato } = selectedSubscription
    if (candidato && candidato.contato) {
      setEditing(true)
      const { logradouro, bairro, cidade, ...rest } = candidato.contato
      await loadCities(String(cidade?.estado?.uf))
      setEnderecoContato({
        logradouro,
        bairro,
        codigoIBGE: cidade.codigoIBGE,
        uf: cidade.estado.uf
      })
      reset({ ...rest })
    }
  }

  useEffect(() => {
    if (isActive) {
      initFormValues()
      window.scrollTo(0, 0)
    }
    if (listUF.length === 0) {
      loadUF()
    }
  }, [isActive])

  const loadUF = async () => {
    const { succeeded, data } = await localidadeService.loadUF()
    if (succeeded && data) {
      const dataMap = data
        .map(item => ({ value: item.uf, label: item.descricao }))
      setListUF(dataMap)
    }
  }

  const loadCities = async (uf: string) => {
    const { succeeded, data } = await localidadeService.loadCities(uf)
    if (succeeded && data) {
      const dataMap = data
        .map(item => ({ value: item.codigoIBGE, label: item.descricao }))
      setListCities(dataMap)
    }
  }

  const handleCep = async (value: string) => {
    if (errors.cep) {
      clearErrors('cep')
    }

    if (editing) {
      const { contato } = selectedSubscription.candidato
      if (contato && contato.cep === value) {
        const { logradouro, bairro, uf, codigoIBGE } = enderecoContato
        if (logradouro && bairro && uf && codigoIBGE) {
          return
        }
      }
    }

    if (value === lastCepSearch.current) {
      return
    }

    const cepInput = value.replace('-', '')
    if (!cepInput || cepInput.trim().length < 8) {
      setError('cep', {
        type: 'manual',
        message: 'Informe um CEP válido'
      })
      lastCepSearch.current = ''
      return
    }

    lastCepSearch.current = value
    setLoadingCep(true)
    const { succeeded, data, message } = await localidadeService.loadCepInfo(cepInput)
    if (succeeded && data) {
      if (data.uf !== 'GO') {
        toast.warn('As inscrições estão destinadas apenas para pessoas residentes no Estado de Goiás')
        setError('cep', {
          type: 'manual',
          message: ''
        })
        setLoadingCep(false)
        return
      }

      await loadCities(data.uf)
      const { cep, ...rest } = data
      setEnderecoContato({
        ...enderecoContato,
        ...rest
      })
      setLoadingCep(false)
      return
    }

    setLoadingCep(false)
    if (!succeeded && message) {
      setEnderecoContato({
        logradouro: '',
        bairro: '',
        codigoIBGE: ''
      })
      toast.warn(message)
    }
  }

  const handleChangeUF = (value: any) => {
    setEnderecoContato({ ...enderecoContato, uf: value })
    loadCities(value)
  }

  const handleChangeCity = (value: any) => {
    setEnderecoContato({ ...enderecoContato, codigoIBGE: value })
  }

  const handleChangeInput = (key: string, value: any) => {
    setEnderecoContato({
      ...enderecoContato,
      [key]: value
    })
  }

  // Função para limpar a validação do campo após o preenchimento automático
  const handleFieldError = ({ fieldError, fieldValue }: any) => {
    if (fieldError && !fieldValue) {
      return fieldError
    }
  }

  const checkNeedToUpdate = (dataForm: any): boolean => {
    if (!editing) return true;

    const { uf, codigoIBGE, ...rest } = dataForm
    const subscriptionCandidateCopy: any = selectedSubscription.candidato.contato
    if (String(uf) !== String(subscriptionCandidateCopy.cidade.estado.uf)) {
      return true
    }

    if (String(codigoIBGE) !== String(subscriptionCandidateCopy.cidade.codigoIBGE)) {
      return true
    }

    for (const field in rest) {
      if (String(dataForm[field]) !== String(subscriptionCandidateCopy[field])) {
        return true
      }
    }
    return false
  }

  const onSubmit = handleSubmit(async (dataForm) => {
    const { uf, codigoIBGE, ...rest } = dataForm

    if (!checkNeedToUpdate(dataForm)) {
      const { data } = await subscriptionService.get(Number(selectedSubscription.id))
      if (data) {
        updateSubscriptionState(data)
      }
      return nextStep()
    }

    if (uf !== 'GO') {
      toast.warn('As inscrições estão destinadas apenas para pessoas residentes no Estado de Goiás')
      return
    }

    const estado = { uf } as EstadoViewModel
    const cidade = { codigoIBGE, estado } as CidadeViewModel
    const contato = { ...rest, cidade } as ContatoViewModel

    let inscricao = {
      ...selectedSubscription,
      candidato: {
        ...selectedSubscription.candidato,
        contato
      }
    } as InscricaoViewModel

    if (inscricao.candidato.responsavel) {
      inscricao.candidato.responsavel.contato = contato
    }

    const { succeeded, message } = await subscriptionService.save(inscricao)
    if (!succeeded) {
      toast.error(message)
      return
    }

    const { data } = await subscriptionService.get(Number(inscricao.id))
    if (data) {
      updateSubscriptionState(data)
    }
    nextStep()
  })

  return (
    <Container
      as="form"
      onSubmit={onSubmit}
    >
      <h3>Endereço e Contato</h3>
      <Grid
        marginTop
        marginBottom
      >
        <InputCep
          name="cep"
          label="CEP"
          inputRef={register}
          onBlur={e => handleCep(e.target.value)}
          invalid={errors.cep}
        />
        <InputText
          name="logradouro"
          label={loadingCep ? 'buscando endereço...' : 'Endereço'}
          inputRef={register}
          value={enderecoContato.logradouro}
          onChange={e => handleChangeInput('logradouro', e.target.value)}
          invalid={handleFieldError({
            fieldError: errors.logradouro,
            fieldValue: enderecoContato.logradouro
          })}
        />
      </Grid>
      <Grid marginBottom>
        <InputText
          name="complemento"
          label="Complemento"
          inputRef={register}
          invalid={errors.complemento}
        />
        <InputText
          name="numero"
          label="Número"
          inputRef={register}
          invalid={errors.numero}
        />
      </Grid>
      <Grid inline marginBottom>
        <InputText
          name="bairro"
          label="Bairro"
          inputRef={register}
          value={enderecoContato.bairro}
          onChange={e => handleChangeInput('bairro', e.target.value)}
          invalid={handleFieldError({
            fieldError: errors.bairro,
            fieldValue: enderecoContato.bairro
          })}
        />
      </Grid>
      <Grid marginBottom>
        <InputSelect
          name="uf"
          label="UF"
          inputRef={register}
          value={enderecoContato.uf}
          onChange={e => handleChangeUF(e.target.value)}
          options={listUF}
          invalid={handleFieldError({
            fieldError: errors.uf,
            fieldValue: enderecoContato.uf
          })}
        />
        <InputSelect
          name="codigoIBGE"
          label="Cidade"
          inputRef={register}
          value={enderecoContato.codigoIBGE}
          onChange={e => handleChangeCity(e.target.value)}
          options={listCities}
          invalid={handleFieldError({
            fieldError: errors.codigoIBGE,
            fieldValue: enderecoContato.codigoIBGE
          })}
        />
      </Grid>
      <Grid inline marginBottom>
        <InputText
          name="email"
          label="Email"
          inputRef={register}
          invalid={errors.email}
        />
      </Grid>
      <Grid marginBottom>
        <InputPhone
          name="telefonePrincipal"
          label="Telefone Principal"
          inputRef={register}
          invalid={errors.telefonePrincipal}
        />
        <InputPhone
          name="telefoneSecundario"
          label="Telefone Secundário"
          inputRef={register}
          invalid={errors.telefoneSecundario}
        />
      </Grid>
      <ButtonNext
        type="submit"
      >
        Avançar <ArrowForwardIcon />
      </ButtonNext>
    </Container>
  )
}
