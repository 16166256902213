import { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { env } from '../../config/env'
import { useAuth } from '../../hooks/auth'
import { authService } from '../../services/auth'
import { psgApi } from '../../services/http'
import { Spinner } from '../spinner'

export function HttpIntercept() {
  const { logged, authState, signIn, signOut } = useAuth()
  const [activeSpinner, setActiveSpinner] = useState<boolean>(false)

  const notAuthUrl = (url: string): boolean => {
    for (let endpoint of env.auth.routerNotAuth) {
      if (url.toLowerCase().startsWith(env.psg.apiUrl + endpoint)) {
        return true
      }
    }
    return false
  }

  const handleRequest = async (config: AxiosRequestConfig) => {
    if (config.activeSpinner) {
      setActiveSpinner(true)
    }

    if (!!config.url && !notAuthUrl(config.url)) {
      if (logged) {
        config.headers.Authorization = `Bearer ${authState.accessToken}`
      }
    }
    return config;
  }

  const handleResponseSuccess = async (response: AxiosResponse) => {
    setActiveSpinner(false)
    return response
  }

  const handleResponseError = async (error: AxiosError) => {
    setActiveSpinner(false)

    if (error.response) {
      const { status, config: originalRequest } = error.response

      if (status === 401 && authState.refreshToken) {
        const refreshedToken = await authService.refreshToken(authState.refreshToken)

        if (refreshedToken) {
          signIn(refreshedToken)
          const retryOriginalRequest = new Promise(resolve => {
            resolve(psgApi(originalRequest))
          })
          return retryOriginalRequest
        }

        signOut()
        return
      }

      return error.response
    }

    return Promise.reject(error)
  }

  const setMiddlewares = () => {
    psgApi
      .interceptors
      .request
      .use(handleRequest)

    psgApi
      .interceptors
      .response
      .use(handleResponseSuccess, handleResponseError)
  }

  useEffect(() => {
    if (logged) {
      setMiddlewares()
    }
  }, [logged])

  return <Spinner ative={activeSpinner} />
}
