import styled from 'styled-components'

export const ButtonAvatar = styled.button`
  margin-left: 1rem;
  background: none;
  border: 0;
`

export const PopoverContent = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  button {
    background: none;
    border: 0;
    padding: .6rem;

    display: flex;
    align-items: center;
    margin-top: 1rem;
    border-radius: .2rem;
    transition: background .3s;

    &:hover {
      background: #dfecf7;
    }

    svg {
      margin-left: .4rem;
      font-size: 1.2rem;
    }
  }
`
