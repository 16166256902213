import { useState } from 'react'
import { useHistory } from 'react-router'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Fade from '@material-ui/core/Fade'
import Divider from '@material-ui/core/Divider'
import { authService } from '../../../services/auth'

export function MenuSmall() {
  const history = useHistory()
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorElement)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorElement(null)
  }

  return (
    <>
      <IconButton
        type="button"
        onClick={handleClick}
      >
        <MenuIcon fontSize="large" />
      </IconButton>
      <Menu
        id="menu-small"
        anchorEl={anchorElement}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem
          onClick={() => history.push('/processos-seletivos')}
        >
          Processos Seletivos
        </MenuItem>
        <Divider light />
        <MenuItem
          onClick={() => history.push('/minhas-inscricoes')}
        >
          Minhas Inscrições
        </MenuItem>
        <Divider light />
        <MenuItem
          onClick={() => authService.redirectToLogout()}
        >
          Desconectar
        </MenuItem>
      </Menu>
    </>
  )
}
