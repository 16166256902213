import styled from 'styled-components'

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 3rem;
  color: #fff;
  font-size: 1.1rem;
  padding: .4rem 1.4rem;
  margin: .6rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

  transition: filter .2s;
  &:hover {
    filter: brightness(.8);
  }

  &.btn-yes {
    background: #c62828;
  }

  &.btn-not {
    background: #6b6a6a;
  }
`
