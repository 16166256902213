import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogContentText from '@material-ui/core/DialogContentText'

interface ModalContentTextProps {
  content: string;
  dividers: boolean;
}

export function ModalContentText({ content, dividers }: ModalContentTextProps) {
  return (
    <MuiDialogContent dividers={dividers}>
      <MuiDialogContentText dangerouslySetInnerHTML={{ __html: content }} />
    </MuiDialogContent>
  )
}
