import { useHistory } from 'react-router'
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'
import EmailIcon from '@material-ui/icons/Email'
import Badge from '@material-ui/core/Badge'
import { AtendimentosViewModel, InscricaoViewModel } from '../../../_types'
import {
  enumSituacao,
  isSitucaoEdit,
  isSitucaoCancelar,
  SITUACAO_AGUARDANDO_RETORNO_CLIENTE
} from '../../../config/enums/inscricao'

import { Container, Tag, TooltipCustom } from './styles'

interface CardSubscriptionProps {
  subscription: InscricaoViewModel;
  handleModalMessage: (messages: AtendimentosViewModel[]) => void;
  handleModalCancel: (idSubscription: number) => void;
}

export function CardSubscription({ subscription, handleModalMessage, handleModalCancel }: CardSubscriptionProps) {
  const history = useHistory()

  const handleClickEdit = () => {
    history.push(`/inscricao/${subscription.id}`)
    return
  }

  const handleClickCancel = async () => {
    handleModalCancel(subscription.id)
  }

  const getStatus = () => {
    const status = enumSituacao.find(s => s.id === subscription.situacao)?.descricao
    return !!status ? status : ''
  }

  const getColorTagStatus = () => {
    const color = enumSituacao.find(s => s.id === subscription.situacao)?.color
    return !!color ? color : ''
  }

  const getDataInscricao = () => {
    if (subscription.dataHoraCadastro) {
      return new Date(subscription.dataHoraCadastro).toLocaleDateString('pt-BR')
    }
  }

  const getPeriodoTurma = () => {
    const turma = subscription.turma
    const dataInicio = new Date(turma.dataInicio).toLocaleDateString('pt-BR')
    const dataFim = new Date(turma.dataFim).toLocaleDateString('pt-BR')
    return `${dataInicio} - ${dataFim}`
  }

  const getHorarioTurma = () => {
    const turma = subscription.turma
    return `${turma.horaInicio.slice(0, 5)} às ${turma.horaFim.slice(0, 5)}`
  }

  const disableMessageButton = () => {
    const hasMessage = subscription.atendimentos.length > 0
    if (hasMessage) {
      const hasObs = subscription.atendimentos.find(a => !!a.observacao)
      if (hasObs) {
        return false
      }
    }
    return true
  }

  const handleClickMessage = () => {
    if (disableMessageButton()) return;

    const messages = subscription.atendimentos.filter(a => !!a.observacao)
    handleModalMessage(messages)
  }

  const highlightMessage = () => {
    if (disableMessageButton()) {
      return false
    }
    return subscription.situacao === SITUACAO_AGUARDANDO_RETORNO_CLIENTE
  }

  return (
    <Container>
      <h3>
        <span>Curso</span>
        <strong>{subscription.turma?.nome}</strong>
        <div className="info-turma">
          <span>Período</span> <strong>{getPeriodoTurma()}</strong>
          <span>Horário</span> <strong>{getHorarioTurma()}</strong>
        </div>
      </h3>
      <hr />
      <div className="info">
        <span>Candidato</span> <strong>{subscription.candidato.nome}</strong>
        <span>Situação</span> <Tag color={getColorTagStatus()}>{getStatus()}</Tag>
        <span>Data Inscrição</span> <strong>{getDataInscricao()}</strong>
      </div>
      <hr />
      <div className="actions">
        <button
          disabled={disableMessageButton()}
          className="msg"
          type="button"
          onClick={handleClickMessage}
        >
          <TooltipCustom
            title={disableMessageButton() ? 'Não há notificações' : 'Notificações'}
            placement="bottom"
          >
            <Badge
              variant="dot"
              color="error"
              invisible={!highlightMessage()}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <EmailIcon />
            </Badge>
          </TooltipCustom>
        </button>
        <button
          className="edit"
          disabled={!isSitucaoEdit(subscription.situacao)}
          type="button"
          onClick={handleClickEdit}
        >
          <TooltipCustom
            title={isSitucaoEdit(subscription.situacao) ? 'Alterar Inscrição' : 'Esta inscrição não pode ser alterada'}
            placement="bottom"
          >
            <EditIcon />
          </TooltipCustom>
        </button>
        <button
          disabled={!isSitucaoCancelar(subscription.situacao)}
          className="cancel"
          type="button"
          onClick={handleClickCancel}
        >
          <TooltipCustom
            title={isSitucaoCancelar(subscription.situacao) ? 'Cancelar Inscrição' : 'Esta inscrição não pode ser cancelada'}
            placement="bottom"
          >
            <CloseIcon />
          </TooltipCustom>
        </button>
      </div>
    </Container>
  )
}
